/*
 * 업무구분: 고객
 * 화 면 명: MSPFS103P
 * 화면설명: MDG/CDD정보비교_P
 * 작 성 일: 2023.03.20
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal large" ref="modal" title="MDG/CDD 정보 비교">
      <template>
        <div class="wrap-modalcontents">
          <h3>MDG/CDD 정보 비교(고객통합관련)</h3>
          <ul class="list-noicon">
            <li>보험ERP에 등록되어 있는 MDG정보와 수익증권 가입 시 등록된 CDD 정보를 비교하십시요.</li>
            <li>정확한 정보를 반영하기 위해 고객님께 의사를 확인하시고 <strong class="colorR">두가지 정보 중 하나</strong>를 선택하여 주십시요.</li>
          </ul>
          <p class="mt-5"><strong class="colorR">수익증권 CDD 정보입니다.</strong></p>
          <p class="mt-2 mb-3"><mo-checkbox v-model="lv_checkCdd">CDD 정보를 선택합니다.</mo-checkbox></p>
          <div class="wrap-table">
            <table class="table row-type">
              <thead>
                <tr>
                  <th rowspan="2" scope="col">우편번호</th>
                  <th scope="col">자택주소</th>
                  <th scope="col">자택전화번호</th>
                  <th scope="col">휴대폰</th>
                </tr>
                <tr>
                  <th scope="col" class="bl-ddd">직장주소</th>
                  <th scope="col">직장전화번호</th>
                  <th scope="col">E-mail주소</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="wsN">{{ lv_cddInfo.cddHomePstcd }}</td>
                  <td class="aL">{{ lv_cddInfo.cddHomeAddr }}</td>
                  <td class="wsN">{{ lv_cddInfo.cddHomeNo }}</td>
                  <td class="wsN">{{ lv_cddInfo.cddCelphNo }}</td>
                </tr>
                <tr>
                  <td class="wsN">{{ lv_cddInfo.cddJobPstcd }}</td>
                  <td class="aL">{{ lv_cddInfo.cddJobAddr }}</td>
                  <td class="wsN">{{ lv_cddInfo.cddJobNo }}</td>
                  <td class="wsN">{{ lv_cddInfo.cddEmail }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-5"><strong class="colorR">보험 ERP MDG 정보입니다.</strong></p>
          <p class="mt-2 mb-3"><mo-checkbox v-model="lv_checkCdi">MDG 정보를 선택합니다.</mo-checkbox></p>
          <div class="wrap-table">
            <table class="table row-type">
              <thead>
                <tr>
                  <th rowspan="2" scope="col">우편번호</th>
                  <th scope="col">자택주소</th>
                  <th scope="col">자택전화번호</th>
                  <th scope="col">휴대폰</th>
                </tr>
                <tr>
                  <th scope="col" class="bl-ddd">직장주소</th>
                  <th scope="col">직장전화번호</th>
                  <th scope="col">E-mail주소</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="wsN">{{ lv_cdiInfo.cdiHomePstcd }}</td>
                  <td class="aL">{{ lv_cdiInfo.cdiHomeAddr }}</td>
                  <td class="wsN">{{ lv_cdiInfo.cdiHomeNo }}</td>
                  <td class="wsN">{{ lv_cdiInfo.cdiCelphNo }}</td>
                </tr>
                <tr>
                  <td class="wsN">{{ lv_cdiInfo.cdiJobPstcd }}</td>
                  <td class="aL">{{ lv_cdiInfo.cdiJobAddr }}</td>
                  <td class="wsN">{{ lv_cdiInfo.cdiJobNo }}</td>
                  <td class="wsN">{{ lv_cdiInfo.cdiEmail }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button size="large" @click="fn_Close()">닫기</mo-button>
          <mo-button size="large" primary @click="fn_ValidConfirm()">확인</mo-button>
        </div>
      </template>
    </mo-modal>

</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS103P",
    screenId: "MSPFS103P",
    components: {},
    props: {
      popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_cddInfo: {}, // CDD고객정보
        lv_cdiInfo: {}, // CDI고객정보

        lv_checkCdd: false, // CDI고객정보 체크여부
        lv_checkCdi: false, // CDI고객정보 체크여부
        lv_checkValue: '',  // CDD/CDI 체크값

      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS103P')
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

      lv_checkCdd: {
        handler(after) {
         if( after ) {
          this.lv_checkCdi = false
         }
        }
      },
      lv_checkCdi: {
        handler(after) {
         if( after ) {
          this.lv_checkCdd = false
         }
        }
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.fn_Init()        
        this.modal.open()
      },
      fn_Close() {
        this.modal.close()
      },
      fn_ValidConfirm() {
        console.log('fn_ValidConfirm.....')

        if( this.lv_checkCdd || this.lv_checkCdi ) {
          // 메인화면으로 선택 정보 넘김

          if( this.lv_checkCdd ) {
            this.lv_checkValue = 'CDD'
            this.fn_CallAlert('CDD정보를 반영하시겠습니까?')

          } else {

            this.lv_checkValue = 'CDI'
            this.fn_CallAlert('MDG정보를 반영하시겠습니까?')
          }

        } else {
          FSCommUtil.gfn_validate(this, 'CDD/MDG 두가지 정보 중 하나를 선택하여 주십시요.')
          return
        }
      },
      fn_Confirm() {
        this.$emit('fs-popup-callback', 1, this.lv_checkValue)
        this.fn_Close()
      },

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS103P] fn_Init...')
        console.log(this.popupObj)

        // CDD고객정보
        this.lv_cddInfo = this.popupObj.pCddInfo

        if( this.lv_cddInfo.bfcer_mn_ctadr_addr_cd == '1' ) {
          // 자택
          this.lv_cddInfo.cddHomePstcd  = ''
          this.lv_cddInfo.cddHomeAddr   = ''
          this.lv_cddInfo.cddHomeNo     = ''          

          // 우편번호
          this.lv_cddInfo.cddHomePstcd = FSCommUtil.gfn_isNull(this.lv_cddInfo.hom_addr_pstcd01) ? '' : this.lv_cddInfo.hom_addr_pstcd01 + this.lv_cddInfo.hom_addr_pstcd02
          // 주소
          this.lv_cddInfo.cddHomeAddr = FSCommUtil.gfn_isNull(this.lv_cddInfo.cust_hom_pstcd_addr) ? '' : this.lv_cddInfo.cust_hom_pstcd_addr + ' ' + this.lv_cddInfo.hom_bldg_no_encr + ' ' + this.lv_cddInfo.cust_hom_dtlad_encr
          // 전화번호
          this.lv_cddInfo.cddHomeNo = FSCommUtil.gfn_isNull(this.lv_cddInfo.hom_dstno_encr) ? '' : this.lv_cddInfo.hom_dstno_encr + ') ' + this.lv_cddInfo.hom_tlnum_no_encr + ' - ' + this.lv_cddInfo.hom_bkno_sno_encr

        } else if( this.lv_cddInfo.bfcer_mn_ctadr_addr_cd == '2' ) { 
          // 직장
          this.lv_cddInfo.cddJobPstcd   = ''
          this.lv_cddInfo.cddJobAddr    = ''
          this.lv_cddInfo.cddJobNo      = ''

          // 우편번호
          this.lv_cddInfo.cddJobPstcd = FSCommUtil.gfn_isNull(this.lv_cddInfo.jobp_addr_pstcd01) ? '' : this.lv_cddInfo.jobp_addr_pstcd01 + this.lv_cddInfo.jobp_addr_pstcd02
          // 주소
          this.lv_cddInfo.cddJobAddr = FSCommUtil.gfn_isNull(this.lv_cddInfo.cust_jobp_pstcd_addr) ? '' : this.lv_cddInfo.cust_jobp_pstcd_addr + ' ' + this.lv_cddInfo.jobp_bldg_no_encr + ' ' + this.lv_cddInfo.cust_jobp_dtlad_encr
          // 전화번호
          this.lv_cddInfo.cddJobNo = FSCommUtil.gfn_isNull(this.lv_cddInfo.jobp_dstno_encr) ? '' : this.lv_cddInfo.jobp_dstno_encr + ') ' + this.lv_cddInfo.jobp_tlnum_no_encr + ' - ' + this.lv_cddInfo.jobp_bkno_sno_encr
        }

        // CDD 휴대폰번호
        this.lv_cddInfo.cddCelphNo = FSCommUtil.gfn_isNull(this.lv_cddInfo.bman_celph_dstno_encr) ? '' : this.lv_cddInfo.bman_celph_dstno_encr + ' - ' + this.lv_cddInfo.bman_celph_tlnum_no_encr + ' - ' + this.lv_cddInfo.bman_celph_bkno_sno_encr
        // CDD EMAIL
        this.lv_cddInfo.cddEmail = FSCommUtil.gfn_isNull(this.lv_cddInfo.cust_emai_addr_encr) ? '' : this.lv_cddInfo.cust_emai_addr_encr + '@' + this.lv_cddInfo.cust_emai_dm_nm


        // CDI고객정보
        this.lv_cdiInfo = this.popupObj.pCdiInfo 

        // if( this.lv_cdiInfo.bfcer_mn_ctadr_addr_cd == '1' ) {
          // 자택
          this.lv_cdiInfo.cdiHomePstcd  = ''
          this.lv_cdiInfo.cdiHomeAddr   = ''
          this.lv_cdiInfo.cdiHomeNo     = ''

          // 우편번호
          this.lv_cdiInfo.cdiHomePstcd = FSCommUtil.gfn_isNull(this.lv_cdiInfo.hom_addr_pstcd01) ? '' : this.lv_cdiInfo.hom_addr_pstcd01 + this.lv_cdiInfo.hom_addr_pstcd02
          // 주소
          this.lv_cdiInfo.cdiHomeAddr = FSCommUtil.gfn_isNull(this.lv_cdiInfo.cust_hom_pstcd_addr) ? '' : this.lv_cdiInfo.cust_hom_pstcd_addr + ' ' + this.lv_cdiInfo.hom_bldg_no_encr + ' ' + this.lv_cdiInfo.cust_hom_dtlad_encr
          // 전화번호
          this.lv_cdiInfo.cdiHomeNo = FSCommUtil.gfn_isNull(this.lv_cdiInfo.hom_dstno_encr) ? '' : this.lv_cdiInfo.hom_dstno_encr + ') ' + this.lv_cdiInfo.hom_tlnum_no_encr + ' - ' + this.lv_cdiInfo.hom_bkno_sno_encr

        // } else if( this.lv_cdiInfo.bfcer_mn_ctadr_addr_cd == '2' ) { 
          // 직장
          this.lv_cdiInfo.cdiJobPstcd   = ''
          this.lv_cdiInfo.cdiJobAddr    = ''
          this.lv_cdiInfo.cdiJobNo      = ''

          // 우편번호
          this.lv_cdiInfo.cdiJobPstcd = FSCommUtil.gfn_isNull(this.lv_cdiInfo.jobp_addr_pstcd01) ? '' : this.lv_cdiInfo.jobp_addr_pstcd01 + this.lv_cdiInfo.jobp_addr_pstcd02
          // 주소
          this.lv_cdiInfo.cdiJobAddr = FSCommUtil.gfn_isNull(this.lv_cdiInfo.cust_jobp_pstcd_addr) ? '' : this.lv_cdiInfo.cust_jobp_pstcd_addr + ' ' + this.lv_cdiInfo.jobp_bldg_no_encr + ' ' + this.lv_cdiInfo.cust_jobp_dtlad_encr
          // 전화번호
          this.lv_cdiInfo.cdiJobNo = FSCommUtil.gfn_isNull(this.lv_cdiInfo.jobp_dstno_encr) ? '' : this.lv_cdiInfo.jobp_dstno_encr + ') ' + this.lv_cdiInfo.jobp_tlnum_no_encr + ' - ' + this.lv_cdiInfo.jobp_bkno_sno_encr

        // }

        // CDI 휴대폰번호
        this.lv_cdiInfo.cdiCelphNo = FSCommUtil.gfn_isNull(this.lv_cdiInfo.bman_celph_dstno_encr) ? '' : this.lv_cdiInfo.bman_celph_dstno_encr + ' - ' + this.lv_cdiInfo.bman_celph_tlnum_no_encr + ' - ' + this.lv_cdiInfo.bman_celph_bkno_sno_encr
        // CDI EMAIL
        this.lv_cdiInfo.cdiEmail = FSCommUtil.gfn_isNull(this.lv_cdiInfo.cust_emai_addr_encr) ? '' : this.lv_cdiInfo.cust_emai_addr_encr + '@' + this.lv_cdiInfo.cust_emai_dm_nm


        this.lv_checkCdd = false            // CDD고객정보 체크여부
        this.lv_checkCdi = false            // CDI고객정보 체크여부
        this.lv_checkValue = ''             // CDD/CDI 체크값
      },

      /******************************************************************************
       * Function명 : fn_CallAlert
       * 설명       : alert popup 호출
       ******************************************************************************/      
      fn_CallAlert(msg) {
          let t_popupObj = {
            confirm: true,
            confirmFunc: this.fn_Confirm,
            content: msg
          }
          this.$emit('fs-alert-popup', 0, t_popupObj)
      },

    }
  }
</script>
<style scoped>
</style>