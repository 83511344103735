/*
 * 업무구분: 고객
 * 화 면 명: MSPFS105P
 * 화면설명: 직원번호(직원검색)_P
 * 작 성 일: 2023.02.13
 * 작 성 자: 이지수
 */
<template>

  <mo-modal class="fts-modal large" ref="modal" :title="pTitle">
      <template>
        <div class="wrap-modalcontents">
          <div class="input-top">
            <div class="left">
              <div class="wrap-input row">
                <label> 사원번호 </label>
                <mo-decimal-field  
                 numeric
                 mask="##########"
                 v-model="dsSearch.staf_eno" 
                 clearable 
                 placeholder="입력하세요" 
                 class="w150"
                @keyup.enter="fn_searchDetail" /><!-- class명 "w150" 추가 2023-04-07 jy -->
              </div>
              <div class="wrap-input row">
                <label> 성명 </label>
                <mo-text-field 
                  v-model="dsSearch.bfcer_eply_nm" 
                  clearable 
                  placeholder="입력하세요"
                  class="w150"
                @keyup.enter="fn_searchDetail" /><!-- class명 "w150" 추가 2023-04-07 jy -->
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button primary class="inquiry" @click="fn_searchDetail"> 조회 </mo-button>
              </div>
            </div>
          </div>
          <div class="wrap-table mt-3 h-scroll"> <!-- class명 "h-scroll" 추가 2023-04-07 jy -->
            <table class="table row-type">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">사원번호</th>
                  <th scope="col">성명</th>
                  <th scope="col">지점</th>
                  <th scope="col">지역단</th>
                  <th scope="col">사업부</th>
                </tr>
              </thead>
              <tbody v-if="dsList.length > 0">
                <tr v-for="(item,idx) in dsList" :key="idx" :class="selectedIdx == idx ? 'checked' : ''" @click="fn_cellClick(idx)">
                  <td><mo-radio v-model="item.checked" @click="fn_cellClick(idx)" :disabled="dsList.length == 0"/></td>
                  <td>{{item.staf_eno}}</td>
                  <td>{{item.bfcer_eply_nm}}</td>
                  <td>{{item.bfcer_org_nm}}</td>
                  <td>{{item.dof_org_nm}}</td>
                  <td>{{item.hof_org_nm}}</td>
                </tr>
                <!--<tr :class="{'checked': valueRadio1}" >
                  <td><mo-radio v-model="valueRadio1" /></td>
                  <td>1234567</td>
                  <td>김삼성</td>
                  <td>강남지점 100팀</td>
                  <td>서울지역단</td>
                  <td>강북경인</td>
                </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button size="large" @click="fn_Close">닫기</mo-button>
          <mo-button primary size="large" @click="fn_confirm">확인</mo-button>
        </div>

      </template>
    </mo-modal>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS105P',
  screenId: 'MSPFS105P',
  components: {},
  props: {
    popupObj: {type: Object, default: null}
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {},

  mounted () {
    this.$bizUtil.insSrnLog('MSPFS105P')
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    modal () {
      return this.$refs.modal
    }
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      dsSearch: {
        bfcer_eply_nm: '', // 수익증권사원명, Length : 50
        bfcer_fof_cd: '', // 수익증권지점코드, Length : 8
        staf_eno: '' // 임직원사번, Length : 10
      },
      dsList: [
        // {checked : false,
        //  staf_eno      : '',
        //  bfcer_eply_nm : '',
        //  brcer_org_nm  : '',
        //  dof_org_nm    : '',
        //  hof_org_nm    : '',
        //  bfcer_fof_cd  : '',
        //  bfcer_user_auth_grp_cd : '',
        //  ivt_prpns_reg_sno : '',
        //  }
      ], // 검색결과

      selectedIdx: '', // 체크 true 인덱스 번호

      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      popNum: 3,
      pTitle: '직원 번호 (직원 검색)'
    }
  },

  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_historyBack
       * 설명       : 뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      this.$router.go(-1)
    },

    /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
    ******************************************************************************/
    fn_init () {
      this.dsSearch = {
        bfcer_eply_nm: '', // 수익증권사원명, Length : 50
        bfcer_fof_cd: '', // 수익증권지점코드, Length : 8
        staf_eno: '' // 임직원사번, Length : 10
      }

      this.dsList = [
        // {checked : false,
        //  staf_eno      : '',
        //  bfcer_eply_nm : '',
        //  brcer_org_nm  : '',
        //  dof_org_nm    : '',
        //  hof_org_nm    : '',
        //  bfcer_fof_cd  : '',
        //  bfcer_user_auth_grp_cd : '',
        //  ivt_prpns_reg_sno : '',
        //  }
      ]

      this.selectedIdx = ''
      this.popNum = 3
      this.pTitle = '직원 번호 (직원 검색)'
    },
    /**********************************************************************************
      * Function명 : fn_searchDetail
      * 설명       : 조회, WFtitleCRUDbtn 의 callback
    **********************************************************************************/
    fn_searchDetail () {
      if (this.dsSearch.staf_eno.length < 2 && this.dsSearch.bfcer_eply_nm.length < 2) {
        // this.fn_AlertPopup("사원번호, 사원명 둘중 하나는 2글자 이상 입력하여 주십시요!")
        FSCommUtil.gfn_validate(this, '사원번호, 사원명 둘중 하나는 2글자 이상 입력하여 주십시요!')
        return false
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570132_S'
      this.eaiCommObj.params = {
        bfcer_eply_nm: this.dsSearch.bfcer_eply_nm, // 수익증권사원명, Length : 50
        bfcer_fof_cd: this.dsSearch.bfcer_fof_cd, // 수익증권지점코드, Length : 8
        staf_eno: this.dsSearch.staf_eno // 임직원사번, Length : 10
      }
      console.log('서비스 호출시 정보', this.eaiCommObj)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },
    /**********************************************************************************
      * Function명 : fn_callBack
      * 설명       : transaction 응답처리
    **********************************************************************************/
    fn_callBack (response) {
      this.dsList = response.data.sy000300_O_00VO

      console.log('data check', this.dsList)
      this.dsList.forEach((item) => {
        item.checked = false
      })

      if (this.dsList.length === 0) {
        this.fn_AlertPopup('사원이 존재하지 않습니다.')
        return false
      }
    },
    /***********************************************************************************
      * Function명 : fn_cellClick
      * 설명       : 조회 데이터 클릭 이벤트
    ***********************************************************************************/
    fn_cellClick (idx) {
      this.dsList.forEach((item) => {
        item.checked = false
      })
      this.selectedIdx = idx
      console.log('셀클릭, 인덱스 = ', idx)
      this.dsList[idx].checked = true
    },

    /***********************************************************************************
      * Function명 : fn_confirm
      * 설명       : 확인 버튼 클릭 이벤트
    ***********************************************************************************/
    fn_confirm () {
      if ( FSCommUtil.gfn_isNull(this.selectedIdx) ) {
        // this.fn_AlertPopup("사원을 선택해 주세요");
        FSCommUtil.gfn_validate(this, '사원을 선택해 주세요')
        return
      }
      var objRtn = this.dsList[this.selectedIdx]
      if ( !FSCommUtil.gfn_isNull( this.popupObj.btnFlag ) ) { objRtn.btnFlag = this.popupObj.btnFlag }
      console.log('popup105 ObjectReturn', objRtn)
      this.$emit('fs-popup-callback', this.popNum, objRtn)

      this.fn_Close(objRtn)
    },

    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (msg) {
      let t_popupObj = {
        confirm: false,
        content: msg
      }
      this.$emit('fs-alert-popup', 0, t_popupObj)
    },

    /***********************************************************************************
      * Function명 : fn_Open
      * 설명       : 팝업 open
    ***********************************************************************************/
    fn_Open () {
      setTimeout(() => {
        this.fn_init()

        if (this.popupObj.popNum !== undefined) {
          this.popNum = this.popupObj.popNum
        }
        if (this.popupObj.pTitle !== undefined) {
          this.pTitle = this.popupObj.pTitle
        }
        this.modal.open()
      }, 10)
    },
    /***********************************************************************************
      * Function명 : fn_close
      * 설명       : 팝업 close
    ***********************************************************************************/
    fn_Close () {
      this.modal.close()
    }
  } // method end
}
</script>
<style scoped>
</style>
