/**
* 업무구분: 고객
* 화 면 명: MSPFS101M
* 화면설명: 개인고객등록1000
* 작 성 일: 2023.02.13
* 작 성 자: 이태흥
* 수정일 : 2024-03-19 강현철 사유 : ASR240200203 / 사랑On3.0_수익증권 CDD/고객등록 커버발행이력 생성 로직 보완
* 수정일 : 2024-04-24 강현철 사유 : ASR240301005 / 투자자정보분석결과보고서 기능 개선 및 마케팅 수집기간 변경
* 수정일 : 2024-08-20 강현철 사유 : ASR240701140 / 고객명 띄어쓰기 허용
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- main start -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 종류 </label>
              <mo-dropdown class="w150" :class="lv_cob_rname_gb.error ? 'error' : ''" :items="lv_commCode.code211" v-model="lv_cob_rname_gb.value" :disabled="lv_cob_rname_gb.disabled" />
            </div>
            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 </label>
              <!-- <mo-text-field
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value"
                :disabled="lv_ed_rname_no.disabled"
                maxlength="13"
                @keyup.enter="fn_OnSearch"
                placeholder="실명번호를 입력하세요"
              /> -->

              <mo-text-field
                ref="ed_rname_no1"
                class="w80"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value1"
                :disabled="lv_ed_rname_no.disabled"
                maxlength="6"
                type="number"
                @keyup="fn_NextFocus"
                @keyup.enter="fn_OnSearch"
                placeholder="앞 6자리"
              />
              <m-trans-key-input
                v-if="isMobile && isMtrans"
                ref="ed_rname_no2"
                class="w80"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value2"
                type="numberMax7"
                dialog="Y"
                start="1"
                end="-1"
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"
                :disabled="lv_ed_rname_no.disabled"
              />
              <mo-text-field
                v-else
                ref="ed_rname_no2"
                class="w80"
                type="password"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value2"
                :disabled="lv_ed_rname_no.disabled"
                maxlength="7"
                @keyup.enter="fn_OnSearch"
                placeholder="뒤 7자리"
              />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
              <mo-button primary class="inquiry" @click="fn_OnSearch()"> 조회 </mo-button>
              <mo-button primary class="inquiry" v-if="lv_serverType === 'local'" @click="fn_ReportPrintTest()"> 보고서출력(테스트용) </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">기본정보</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <!-- <colgroup>
              <col width="12%">
              <col width="38%">
              <col width="12%">
              <col width="38%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 고객명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="cust_nm"
                      class="input-long"
                      :class="lv_cust_nm.error ? 'error' : ''"
                      v-model="lv_cust_nm.value"
                      :disabled="lv_cust_nm.disabled"
                      @blur="fn_Keyup($event, { objName: 'lv_cust_nm', keyName: 'value' }, 'ssnf')"
                      clearable
                    />
                  </div>
                </td>
                <th colspan="2">
                  <span> 고객영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_cust_eng_nm.value" clearable />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code981" :class="lv_naty_cntry_cd.error ? 'error' : ''" v-model="lv_naty_cntry_cd.value" placeholder="선택하세요" />
                  </div>
                </td>
                <th colspan="2">
                  <span class="emphasis"> 거주국가 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code981" :class="lv_fnd_dmcl_cntry_cd.error ? 'error' : ''" v-model="lv_fnd_dmcl_cntry_cd.value" placeholder="선택하세요" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 소득자구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code206" v-model="lv_cob_inc_earner_gb.value" :class="lv_cob_inc_earner_gb.error ? 'error' : ''" placeholder="선택하세요" />
                  </div>
                </td>
                <th colspan="2">
                  <span class="emphasis"> 거주자여부 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="residentPerson" v-model="lv_dwlr_yn.value" class="row" :class="lv_dwlr_yn.error ? 'error' : ''" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 생계형구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code210" v-model="lv_lvht_sc_cd.value" placeholder="선택하세요" />
                  </div>
                </td>
                <th rowspan="4" class="w80">
                  <span class="emphasis"> 신용정보 동의여부 </span>
                </th>
                <td class="w90 border-left">
                  <span class="label-sub"> 계약체결 </span>
                </td>
                <td>
                  <!-- 신용정보 동의여부1 -->
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="creditAgree" v-model="lv_credit_agree.value" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 생일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <div class="picker row">
                      <mo-date-picker v-model="lv_cal_birth_dt.value" :bottom="false" />
                    </div>
                    <div class="radio row">
                      <mo-radio-wrapper :items="birthLunar" v-model="lv_birth_solun_gb.value" class="row" />
                    </div>
                  </div>
                </td>
                <td class="w90 border-left">
                  <span class="label-sub"> 마케팅 </span>
                </td>
                <td>
                  <!-- 신용정보 동의여부2 -->
                  <div class="wrap-input row">
                    <mo-radio-wrapper
                      :class="lv_marketing_agree.error ? 'error' : ''"
                      :items="marketingAgree"
                      v-model="lv_marketing_agree.value"
                      :disabled="lv_marketing_agree.disabled"
                      @input="fn_clear('marketing')"
                      ref="marketing_agree"
                    />
                    <span v-if="lv_marketing_date.display">
                      ( 종료일자
                      <mo-date-picker v-model="lv_marketing_date.value2" :bottom="false" class="w104 mr5" disabled /> )
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 우편물통보처 </span>
                </th>
                <td>
                  <mo-dropdown :class="lv_mail_trad_cd.error ? 'error' : ''" :items="lv_commCode.code221" v-model="lv_mail_trad_cd.value" placeholder="선택하세요" />
                </td>
                <td class="w90 border-left" rowspan="2">
                  <span class="label-sub"> 연락방식 </span>
                </td>
                <td rowspan="2">
                  <!-- 신용정보 동의여부3 -->
                  <div class="wrap-input row">
                    <div class="wrap-rowspan">
                      <div class="row wrap-switch">
                        <div class="row">
                          <div class="row">
                            <span class="title-switch"> 전화 </span>
                            <mo-switch v-model="lv_agree_phone" class="switch-t1" disabled />
                          </div>
                          <div class="row">
                            <span class="title-switch wS"> 문자 </span>
                            <mo-switch v-model="lv_agree_sms" class="switch-t1" disabled />
                          </div>
                        </div>
                      </div>
                      <div class="row wrap-switch">
                        <div class="row">
                          <span class="title-switch"> E-mail </span>
                          <mo-switch v-model="lv_agree_email" class="switch-t1" disabled />
                        </div>
                        <div class="row">
                          <span class="title-switch wS"> 우편 </span>
                          <mo-switch v-model="lv_agree_post" class="switch-t1" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 관리자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="findEmployee01 w68" :class="lv_ed_mgr_empno.error ? 'error' : ''" v-model="lv_ed_mgr_empno.value" disabled />
                    <mo-button @click="fn_OpenPopup(3)"> 사원찾기 </mo-button>
                    <mo-text-field class="findEmployee02" :class="lv_ed_mgr_empno.error ? 'error' : ''" v-model="lv_ed_mgr_empnm.value" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 펀드잔고통보처 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_fnd_blnc_ntc_sc_cd.error ? 'error' : ''"
                      :items="lv_commCode.code223"
                      v-model="lv_fnd_blnc_ntc_sc_cd.value"
                      placeholder="선택하세요"
                      @input="fn_clear('fndBlnc')"
                      ref="fnd_blnc_ntc_sc_cd"
                    />
                  </div>
                </td>
                <th colspan="2">
                  <span> 주연락처 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="lv_commCode.code215" v-model="lv_main_dm.value" placeholder="선택하세요" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title">연락처정보</h2>
          <span class="red">{{ lv_infoGuide }}</span>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <!-- <colgroup>
              <col width="12%">
              <col width="38%">
              <col width="12%">
              <col width="38%">
            </colgroup> -->
            <tbody>
              <tr>
                <th rowspan="2">
                  <span :class="lv_home_addr.class ? 'emphasis' : ''"> 자택주소 </span>
                </th>
                <td>
                  <!-- 자택주소1 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_home_addr.error ? 'error' : ''" v-model="lv_home_addr.zipNum" disabled />
                    <mo-button class="btn-zip" @click="fn_OpenPopup(0, 'HOME')" :disabled="lv_home_addr.delete"> 우편번호 </mo-button>
                    <mo-checkbox v-model="lv_home_addr.delete" class="row"> 삭제 </mo-checkbox>
                    <!-- [수정] 주소표준화여부 표시 변경 -->
                    <!-- <div class="row wrap-switch2">
                      <span> 주소표준화여부 </span>
                      <i>Y</i>
                    </div> -->
                    <div class="row wrap-switch">
                      <span class="title-switch wL"> 주소표준화여부 </span>
                      <mo-switch v-model="lv_home_addr.standard" size="small" class="switch-t2" disabled />
                    </div>
                  </div>
                </td>
                <th>
                  <span :class="lv_home_no.class ? 'emphasis' : ''"> 자택전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="home_no1"
                      class="input-long"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value1"
                      :disabled="lv_home_no.delete"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-text-field
                      ref="home_no2"
                      class="input-long"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value2"
                      :disabled="lv_home_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_home_no', keyName: 'value2' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-text-field
                      ref="home_no3"
                      class="input-long"
                      :class="lv_home_no.error ? 'error' : ''"
                      v-model="lv_home_no.value3"
                      :disabled="lv_home_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_home_no', keyName: 'value3' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-checkbox v-model="lv_home_no.delete" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <!-- 자택주소2 -->
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_home_addr.error ? 'error' : ''" v-model="lv_home_addr.value1" disabled clearable />
                    <mo-text-field :class="lv_home_addr.error ? 'error' : ''" v-model="lv_home_addr.value2" disabled class="w68" clearable />
                    <mo-text-field :class="lv_home_addr.error ? 'error' : ''" v-model="lv_home_addr.value3" disabled clearable />
                    <mo-text-field :class="lv_home_addr.error ? 'error' : ''" v-model="lv_home_addr.value4" disabled clearable />
                  </div>
                </td>
              </tr>
              <tr>
                <th>직장명</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_job_name.value" placeholder="입력하세요" clearable />
                  </div>
                </td>
                <th>
                  <span> 부서명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="lv_dept_name.value" placeholder="입력하세요" clearable />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="2">
                  <span :class="lv_job_addr.class ? 'emphasis' : ''"> 직장주소 </span>
                </th>
                <td>
                  <!-- 직장주소1 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_job_addr.error ? 'error' : ''" v-model="lv_job_addr.zipNum" disabled />
                    <mo-button class="btn-zip" @click="fn_OpenPopup(0, 'JOB')" :disabled="lv_job_addr.delete"> 우편번호 </mo-button>
                    <mo-checkbox v-model="lv_job_addr.delete" class="row"> 삭제 </mo-checkbox>
                    <!-- [수정] 주소표준화여부 표시 변경 -->
                    <!-- <div class="row wrap-switch2">
                      <span> 주소표준화여부 </span>
                      <i>Y</i>
                    </div> -->
                    <div class="row wrap-switch">
                      <span class="title-switch wL"> 주소표준화여부 </span>
                      <mo-switch v-model="lv_job_addr.standard" size="small" class="switch-t2" disabled />
                    </div>
                  </div>
                </td>
                <th>
                  <span :class="lv_job_no.class ? 'emphasis' : ''"> 직장전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="job_no1"
                      class="input-long"
                      :class="lv_job_no.error ? 'error' : ''"
                      v-model="lv_job_no.value1"
                      :disabled="lv_job_no.delete"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-text-field
                      ref="job_no2"
                      class="input-long"
                      :class="lv_job_no.error ? 'error' : ''"
                      v-model="lv_job_no.value2"
                      :disabled="lv_job_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_job_no', keyName: 'value2' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-text-field
                      ref="job_no3"
                      class="input-long"
                      :class="lv_job_no.error ? 'error' : ''"
                      v-model="lv_job_no.value3"
                      :disabled="lv_job_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_job_no', keyName: 'value3' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-checkbox v-model="lv_job_no.delete" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <!-- 직장주소2 -->
                  <div class="wrap-input row">
                    <mo-text-field :class="lv_job_addr.error ? 'error' : ''" v-model="lv_job_addr.value1" disabled clearable />
                    <mo-text-field :class="lv_job_addr.error ? 'error' : ''" v-model="lv_job_addr.value2" disabled class="w68" clearable />
                    <mo-text-field :class="lv_job_addr.error ? 'error' : ''" v-model="lv_job_addr.value3" disabled clearable />
                    <mo-text-field :class="lv_job_addr.error ? 'error' : ''" v-model="lv_job_addr.value4" disabled clearable />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> FAX번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_fax_no.error ? 'error' : ''" v-model="lv_fax_no.value1" :disabled="lv_fax_no.delete" type="number" maxlength="4" clearable />
                    <mo-text-field
                      class="input-long"
                      v-model="lv_fax_no.value2"
                      :disabled="lv_fax_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_fax_no', keyName: 'value2' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-text-field
                      class="input-long"
                      v-model="lv_fax_no.value3"
                      :disabled="lv_fax_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_fax_no', keyName: 'value3' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                    />
                    <mo-checkbox v-model="lv_fax_no.delete" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
                <th>
                  <span :class="lv_phone_no.class ? 'emphasis' : ''"> 휴대폰번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :class="lv_phone_no.error ? 'error' : ''"
                      :items="lv_commCode.code370"
                      v-model="lv_phone_no.value1"
                      :disabled="lv_phone_no.delete"
                      placeholder="선택하세요"
                      @input="fn_clear('phone')"
                    />
                    <mo-text-field
                      ref="phone_no2"
                      class="input-long"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model.trim="lv_phone_no.value2"
                      :disabled="lv_phone_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_phone_no', keyName: 'value2' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                      @input="fn_clear('phone')"
                    />
                    <mo-text-field
                      ref="phone_no3"
                      class="input-long"
                      :class="lv_phone_no.error ? 'error' : ''"
                      v-model.trim="lv_phone_no.value3"
                      :disabled="lv_phone_no.delete"
                      @keyup.space="fn_Keyup($event, { objName: 'lv_phone_no', keyName: 'value3' }, 'trim')"
                      type="number"
                      maxlength="4"
                      clearable
                      @input="fn_clear('phone')"
                    />
                    <mo-checkbox v-model="lv_phone_no.delete" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span :class="lv_email_addr.class ? 'emphasis' : ''"> E-mail </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="email_addr1"
                        class="input-email01 input-long"
                        :class="lv_email_addr.error ? 'error' : ''"
                        placeholder="입력하세요"
                        v-model="lv_email_addr.value1"
                        :disabled="lv_email_addr.delete"
                        @keyup.space="fn_Keyup($event, { objName: 'lv_email_addr', keyName: 'value1' }, 'trim')"
                        maxlength="20"
                        clearable
                        @input="fn_clear('email')"
                      />
                      <span class="ico-between-email"> @ </span>
                      <mo-text-field
                        ref="email_addr2"
                        class="input-email02 input-long"
                        :class="lv_email_addr.error ? 'error' : ''"
                        placeholder="입력하세요"
                        v-model="lv_email_addr.value2"
                        :disabled="lv_email_addr.delete"
                        @keyup.space="fn_Keyup($event, { objName: 'lv_email_addr', keyName: 'value2' }, 'trim')"
                        maxlength="20"
                        clearable
                        @input="fn_clear('email')"
                      />
                      <mo-checkbox v-model="lv_email_addr.delete" class="row"> 삭제 </mo-checkbox>
                    </div>
                  </div>
                </td>
                <th>
                  <span> 지정고객번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="appoint_cust_no"
                      class="input-long"
                      :class="lv_appoint_cust_no.error ? 'error' : ''"
                      v-model="lv_appoint_cust_no.value"
                      type="number"
                      maxlength="7"
                      placeholder="입력하세요"
                      clearable
                      @input="fn_clear('appointCust')"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button v-if="lv_isProcess" point size="large" @click="fn_GoNextPage('NEXT')" :disabled="lv_btn_next.disabled"> 다음 </mo-button>
          </div>
          <!-- <mo-button primary size="large" :disabled="lv_btn_confirm" @click="fn_CustSubmit()"> 확인 </mo-button> -->
          <mo-button primary size="large" @click="fn_CustSubmit()"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- main end -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup100 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>

    <!-- popup103 -->
    <msp-fs-103p ref="popup103" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup103Obj"></msp-fs-103p>

    <!-- popup104 -->
    <msp-fs-104p ref="popup104" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup104Obj"></msp-fs-104p>

    <!-- popup105 -->
    <msp-fs-105p ref="popup105" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup105Obj"></msp-fs-105p>

    <!-- popup106 -->
    <msp-fs-106p ref="popup106" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup106Obj"></msp-fs-106p>

    <!-- popup107 -->
    <msp-fs-107p ref="popup107" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup107Obj"></msp-fs-107p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSDocForm from '@/ui/fs/comm_elst/FSDocFormUtil'

import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)
import MSPFS103P from '~/src/ui/fs/MSPFS103P' // MDG/CDD 정보비교 팝업
import MSPFS104P from '~/src/ui/fs/MSPFS104P' // 고객정보 팝업
import MSPFS105P from '~/src/ui/fs/MSPFS105P' // 직원번호(직원검색) 팝업
import MSPFS106P from '~/src/ui/fs/MSPFS106P' // 마케팅 동의여부 팝업
import MSPFS107P from '~/src/ui/fs/MSPFS107P' // 고객ID선택 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS101M',
  screenId: 'MSPFS101M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-100p': MSPFS100P,
    'msp-fs-103p': MSPFS103P,
    'msp-fs-104p': MSPFS104P,
    'msp-fs-105p': MSPFS105P,
    'msp-fs-106p': MSPFS106P,
    'msp-fs-107p': MSPFS107P
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '개인고객등록',
        step: 3
      },
      lv_marketing_chk: '',
      pAlertPopupObj: {},
      lv_commCode: {},
      lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
      lv_isClear: false, // 보안키패드 초기화

      // 팝업 객체
      pPopup100Obj: {},
      pPopup103Obj: {},
      pPopup104Obj: {},
      pPopup105Obj: {},
      pPopup106Obj: {},
      pPopup107Obj: {},

      // 검색조건
      lv_cob_rname_gb: {}, // 실명번호종류
      lv_ed_rname_no: {}, // 실명번호
      lv_btn_confirm: true, // 확인버튼 활성/비활성
      lv_btn_next: {}, // 다음버튼

      // 조회시 매핑 데이터로만 사용
      lv_cdd_cust_nm: '', // CDD고객명
      lv_cddCustObj: {}, // CDD고객정보
      lv_uportalObj: {}, // 선택한 유포탈고객정보
      lv_cdiInfoObj: {}, // CDI고객정보

      // 기본정보
      lv_cust_nm: {}, // 고객명
      lv_cust_eng_nm: {}, // 고객영문명

      lv_naty_cntry_cd: {}, // 국적
      lv_fnd_dmcl_cntry_cd: {}, // 거주국가

      lv_cob_inc_earner_gb: {}, // 소득자구분
      lv_dwlr_yn: {}, // 거주자여부

      lv_lvht_sc_cd: {}, // 생계형구분
      lv_cal_birth_dt: {}, // 생일 > 날짜
      lv_birth_solun_gb: {}, // 생일 > 양력/음력 선택
      lv_mail_trad_cd: {}, // 우편물통보처
      lv_ed_mgr_empno: {}, // 관리자사번
      lv_ed_mgr_empnm: {}, // 관리자이름
      lv_fnd_blnc_ntc_sc_cd: {}, // 펀드잔고통보처

      lv_credit_agree: {}, // 신용정보 동의여부 > 계약체결
      lv_marketing_agree: {}, // 신용정보 동의여부 > 마케팅 동의여부
      lv_marketing_date: {}, // 신용정보 동의여부 > 마케팅 시작/종료/유효일자

      lv_agree_phone: false, // 신용정보 동의여부 > 연락방식 > 전화
      lv_agree_sms: false, // 신용정보 동의여부 > 연락방식 > 문자
      lv_agree_email: false, // 신용정보 동의여부 > 연락방식 > E-mail
      lv_agree_post: false, // 신용정보 동의여부 > 연락방식 > 우편

      lv_main_dm: 0, // 주연락처

      // 연락처정보
      lv_infoGuide: '', // 연락처정보 안내문구

      lv_home_addr: {}, // 자택주소
      lv_home_no: {}, // 자택전화번호

      lv_job_name: {}, // 직장명
      lv_dept_name: {}, // 부서명

      lv_job_addr: {}, // 직장주소
      lv_job_no: {}, // 직장전화번호

      lv_fax_no: {}, // FAX번호
      lv_phone_no: {}, // 휴대폰번호
      lv_email_addr: {}, // email주소
      lv_appoint_cust_no: {}, // 지정고객번호

      // 동기화여부
      lv_chk_cdi_sync_yn: 0,

      // INSERT DATASET
      lv_params: {},

      lv_submit_data: {}, // AS-IS: ds_cu1000
      lv_cdi_bp_data: [], // AS-IS: ds_cdi_bp
      lv_cdi_pass_data: [], // AS-IS: ds_cdi_pass
      lv_cdi_addr_data: [], // AS-IS: ds_cdi_addr
      lv_cdi_mka_data: [], // AS-IS: ds_cdi_mka

      lv_userInfo: '',
      lv_serverType: '',
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      lv_cust_no: '', // 리포트 데이터 파라미터
      lv_masked_val: '', // 마스크 변수 체크

      /***********************************************************************************
       * F10570118 : 개인고객정보조회
       * F10570117 : CDD고객조회
       * F10570119 : 유포탈고객ID다건조회
       * F10570120 : CDI고객정보조회
       * F10570121 : 개인고객정보등록
       * F10570122 : 보고서출력
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()

    this.lv_userInfo = this.getStore('userInfo').getters.getUserInfo
    // console.log(this.lv_userInfo)
    this.lv_serverType = process.env.NODE_ENV
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog('MSPFS101M')

    // CDD고객정보에서 등록/수정 후 진입한 경우
    if (!FSCommUtil.gfn_isNull(this.$route.params.cob_rname_gb)) {
      setTimeout(() => {
        this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb
        this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0]
        this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1]

        // 개인고객정보조회
        this.fn_OnSearch()
      }, 100)
    }
  },

  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    residentPerson() {
      // 거주자여부
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    },
    creditAgree() {
      // 신용정보 동의여부 > 계약체결
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      return rtn
    },
    birthLunar() {
      // 생일 > 양력, 음력 선택
      let rtn = []
      rtn.push({ value: '01', text: '양력' })
      rtn.push({ value: '02', text: '음력' })
      return rtn
    },
    marketingAgree() {
      // 신용정보 동의여부 > 마케팅 동의
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 신용정보 동의여부 > 마케팅 동의여부
    'lv_marketing_agree.value': {
      handler(after, before) {
        if (after == 'Y') {
          if (this.lv_marketing_chk != 'Y') {
            this.lv_marketing_chk = ''
            this.fn_OpenPopup(4)
          }
        } else {
          this.lv_marketing_chk = ''
          this.lv_marketing_date.display = false
          this.lv_marketing_date.value2 = ''

          this.lv_agree_phone = false // 신용정보 동의여부 > 연락방식 > 전화
          this.lv_agree_sms = false // 신용정보 동의여부 > 연락방식 > 문자
          this.lv_agree_email = false // 신용정보 동의여부 > 연락방식 > E-mail
          this.lv_agree_post = false // 신용정보 동의여부 > 연락방식 > 우편
        }
      },
      deep: true
    },

    // 우편물통보처
    lv_mail_trad_cd: {
      handler(after, before) {
        this.fn_ChangeMailTrad(after)
      },
      deep: true
    },

    // 펀드잔고통보처
    lv_fnd_blnc_ntc_sc_cd: {
      handler(after, before) {
        this.fn_ChangeMailTrad(this.lv_mail_trad_cd)
      },
      deep: true
    },

    // 주연락처
    'lv_main_dm.value': {
      handler(after, before) {
        this.lv_home_no.class = false
        this.lv_job_no.class = false

        if (after == '1') {
          this.lv_home_no.class = true
        } else if (after == '2') {
          this.lv_job_no.class = true
        }
      },
      deep: true
    },

    // 자택주소 삭제
    'lv_home_addr.delete': {
      handler(after, before) {
        if (after) {
          this.lv_home_addr.zipNum = ''
          this.lv_home_addr.value1 = ''
          this.lv_home_addr.value2 = ''
          this.lv_home_addr.value3 = ''
          this.lv_home_addr.value4 = ''
          this.lv_home_addr.standard = false
        }
      },
      deep: true
    },

    // 자택전화번호 삭제
    'lv_home_no.delete': {
      handler(after, before) {
        if (after) {
          this.lv_home_no.value1 = ''
          this.lv_home_no.value2 = ''
          this.lv_home_no.value3 = ''
        }
      },
      deep: true
    },

    // 직장주소 삭제
    'lv_job_addr.delete': {
      handler(after, before) {
        if (after) {
          this.lv_job_addr.zipNum = ''
          this.lv_job_addr.value1 = ''
          this.lv_job_addr.value2 = ''
          this.lv_job_addr.value3 = ''
          this.lv_job_addr.value4 = ''
          this.lv_job_addr.standard = false
        }
      },
      deep: true
    },

    // 직장전화번호 삭제
    'lv_job_no.delete': {
      handler(after, before) {
        if (after) {
          this.lv_job_no.value1 = ''
          this.lv_job_no.value2 = ''
          this.lv_job_no.value3 = ''
        }
      },
      deep: true
    },

    // 팩스번호 삭제
    'lv_fax_no.delete': {
      handler(after, before) {
        if (after) {
          this.lv_fax_no.value1 = ''
          this.lv_fax_no.value2 = ''
          this.lv_fax_no.value3 = ''
        }
      },
      deep: true
    },

    // 휴대폰 삭제
    'lv_phone_no.delete': {
      handler(after, before) {
        if (after) {
          // this.lv_phone_no.value1 = ''
          this.lv_phone_no.value2 = ''
          this.lv_phone_no.value3 = ''
        }
      },
      deep: true
    },

    // 이메일 삭제
    'lv_email_addr.delete': {
      handler(after, before) {
        if (after) {
          this.lv_email_addr.value1 = ''
          this.lv_email_addr.value2 = ''
        }
      },
      deep: true
    },
    // 실명번호
    'lv_cob_rname_gb.value': {
      handler(after, before) {
        if (after) {
          this.lv_cust_nm.value = ''
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if (this.lv_masked_val.length >= 7) {
        this.fn_OnSearch() // 개인고객정보조회
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '206', dayChkYn: 'N' },
        { cId: '210', dayChkYn: 'N' },
        { cId: '211', dayChkYn: 'N' },
        { cId: '215', dayChkYn: 'N' },
        { cId: '221', dayChkYn: 'N' },
        { cId: '223', dayChkYn: 'N' },
        { cId: '370', dayChkYn: 'N' },
        { cId: '981', dayChkYn: 'N' }
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      // 실명번호종류 코드 재설정
      let t_codeList211 = []
      pResult.code211.forEach((item) => {
        if (item.value == '01' || item.value == '04' || item.value == '05') {
          t_codeList211.push(item)
        }
      })
      this.lv_commCode.code211 = t_codeList211

      // 소득자구분 코드 재설정
      let t_codeList206 = []
      pResult.code206.forEach((item) => {
        if (item.value == '111' || item.value == '131' || item.value == '141') {
          t_codeList206.push(item)
        }
      })
      this.lv_commCode.code206 = t_codeList206

      // 우편물통보처 코드 재설정
      let t_codeList221 = []
      pResult.code221.forEach((item) => {
        if (item.value == '14') {
          t_codeList221.push(item)
        }
        if (item.value == '15') {
          t_codeList221.unshift(item)
        }
      })
      pResult.code221.forEach((item) => {
        if (item.value != '14' && item.value != '15') {
          t_codeList221.push(item)
        }
      })
      this.lv_commCode.code221 = t_codeList221
    },

    /************************* AS-IS 스크립트 시작 *********************************
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
    fn_Init(btnTtype) {
      console.log('[MSPFS101M] fn_Init...')
      console.log('isProcess :: ' + this.lv_isProcess)
      if (btnTtype != 'search') {
        // 검색조건
        this.lv_cob_rname_gb = FSCommUtil.gfn_dataSet(0, '01') // 실명번호 종류 초기화
        // this.lv_ed_rname_no         = FSCommUtil.gfn_dataSet(1)        // 실명번호 입력 초기화
        this.lv_ed_rname_no = {
          error: false,
          disabled: false,
          value1: '',
          value2: ''
        }
        this.lv_cal_birth_dt = FSCommUtil.gfn_dataSet(1) // 생일 초기화
        this.lv_birth_solun_gb = FSCommUtil.gfn_dataSet(0, '01') // 생일 > 양력/음력 선택
        // 보안키패드 초기화
        this.lv_isClear = !this.lv_isClear ? true : false
      }

      this.lv_btn_confirm = true // 확인버튼 비활성화
      this.lv_btn_next = { disabled: true } // 다음버튼

      // 조회시 매핑 데이터로만 사용
      this.lv_cdd_cust_nm = '' // CDD고객명
      this.lv_cddCustObj = {} // CDD고객정보
      this.lv_uportalObj = {} // 선택한 유포탈고객정보

      // 기본정보
      // this.lv_cust_nm             = FSCommUtil.gfn_dataSet(1)        // 고객명 초기화
      this.lv_cust_nm = FSCommUtil.gfn_dataSet(1, '', false, '', 'cust_nm') // 고객명 초기화
      this.lv_cust_eng_nm = FSCommUtil.gfn_dataSet(1) // 고객영문명 초기화

      this.lv_naty_cntry_cd = FSCommUtil.gfn_dataSet(0, 'KR') // 국적 초기화
      this.lv_fnd_dmcl_cntry_cd = FSCommUtil.gfn_dataSet(0, 'KR') // 거주국가 초기화

      this.lv_cob_inc_earner_gb = FSCommUtil.gfn_dataSet(0, '111') // 소득자구분 초기화
      this.lv_dwlr_yn = FSCommUtil.gfn_dataSet(0, 'Y') // 거주자여부 초기화

      this.lv_lvht_sc_cd = FSCommUtil.gfn_dataSet(0, '0') // 생계형구분 초기화

      this.lv_mail_trad_cd = FSCommUtil.gfn_dataSet(0, '15') // 우편물통보처 초기화
      this.lv_ed_mgr_empno = FSCommUtil.gfn_dataSet(1) // 관리자사번 초기화
      this.lv_ed_mgr_empnm = FSCommUtil.gfn_dataSet(1) // 관리자이름 초기화

      this.lv_fnd_blnc_ntc_sc_cd = FSCommUtil.gfn_dataSet(1) // 펀드잔고통보처 초기화

      this.lv_credit_agree = FSCommUtil.gfn_dataSet(0, 'N') // 신용정보 동의여부 > 계약체결
      this.lv_marketing_agree = FSCommUtil.gfn_dataSet(0, '') // 신용정보 동의여부 > 마케팅 동의여부
      this.lv_marketing_date = { display: false, value1: '', value2: '', value3: '' } // 신용정보 동의여부 > 마케팅 시작/종료/유효일자

      this.lv_main_dm = FSCommUtil.gfn_dataSet(0, '0') // 주연락처

      // 연락처정보
      this.lv_infoGuide = '※ 전화번호는 자택, 직장 중에 필수 입력해야 합니다.' // 안내문구
      // 자택주소
      this.lv_home_addr = {
        class: false,
        error: false,
        delete: false,
        standard: false,
        zipNum: '',
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      }
      // 자택전화번호
      this.lv_home_no = {
        class: false,
        error: false,
        delete: false,
        value1: '',
        value2: '',
        value3: ''
      }

      this.lv_job_name = FSCommUtil.gfn_dataSet(1) // 직장명
      this.lv_dept_name = FSCommUtil.gfn_dataSet(1) // 부서명

      // 직장주소
      this.lv_job_addr = {
        class: false,
        error: false,
        delete: false,
        standard: false,
        zipNum: '',
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      }
      // 직장전화번호
      this.lv_job_no = {
        class: false,
        error: false,
        delete: false,
        value1: '',
        value2: '',
        value3: ''
      }

      // FAX번호
      this.lv_fax_no = {
        error: false,
        delete: false,
        value1: '',
        value2: '',
        value3: ''
      }

      // 휴대폰번호
      this.lv_phone_no = {
        class: false,
        error: false,
        delete: false,
        value1: '010',
        value2: '',
        value3: ''
      }

      // email주소
      this.lv_email_addr = {
        class: false,
        error: false,
        delete: false,
        value1: '',
        value2: '',
        value3: ''
      }

      this.lv_appoint_cust_no = FSCommUtil.gfn_dataSet(1) // 지정고객번호

      // 동기화여부
      this.lv_chk_cdi_sync_yn = 0

      // insert data
      this.lv_cdi_bp_data = [] // AS-IS: ds_cdi_bp
      this.lv_cdi_pass_data = [] // AS-IS: ds_cdi_pass
      this.lv_cdi_addr_data = [] // AS-IS: ds_cdi_addr
      this.lv_cdi_mka_data = [] // AS-IS: ds_cdi_mka

      // AS-IS: ds_cu1000
      this.lv_submit_data = {
        bfcer_cust_no: '', // 수익증권고객번호
        bfcer_rlnm_no_knd_cd: '', // 수익증권실명번호종류코드
        rlnm_cnf_no_encr: '', // 실명확인번호암호화
        rlnm_no_er_yn: '', // 실명번호오류여부
        corp_reg_no: '', // 법인등록번호
        fnd_cust_nm: '', // 펀드고객명
        unty_apamt_cnvr_yn: '', // 통합예수금전환여부
        bkkp_nm: '', // 부기명
        bfcer_cust_stat_cd: '', // 수익증권고객상태코드
        fnd_cust_eng_nm: '', // 펀드고객영문명
        eng_nm_use_yn: '', // 영문명사용여부
        bfcer_resnt_nm: '', // 수익증권대표자명
        bfcer_cust_clsf_sc_cd: '', // 수익증권고객분류구분코드
        corp_yn: '', // 법인여부
        bfcer_naty_cntry_cd: '', // 수익증권국적국가코드
        fnd_dmcl_cntry_cd: '', // 펀드거주지국가코드
        dwlr_yn: '', // 거주자여부
        crinf_cnsnt_yn: '', // 신용정보제공동의여부
        bfcer_incmr_sc_cd: '', // 수익증권소득자구분코드
        bfcer_fnd_taxt_sc_cd: '', // 수익증권펀드과세구분코드
        bfcer_lvht_sc_cd: '', // 수익증권생계형구분코드
        cust_mngr_eno: '', // 고객관리자사번
        cust_birth_mmdd: '', // 고객생년월일
        bfcer_slrc_lnrc_sc_cd: '', // 수익증권양력음력구분코드
        bfcer_mn_ctadr_addr_cd: '', // 수익증권주연락처주소코드
        hom_addr_pstcd01: '', // 자택주소우편번호01
        hom_addr_pstcd02: '', // 자택주소우편번호02
        cust_hom_pstcd_addr: '', // 고객자택우편번호주소
        cust_hom_dtlad_encr: '', // 고객자택상세주소암호화
        hom_dstno_encr: '', // 자택지역번호암호화
        hom_tlnum_no_encr: '', // 자택국번번호암호화
        hom_bkno_sno_encr: '', // 자택뒷자리일련번호암호화
        bman_celph_dstno_encr: '', // 사업자휴대폰지역번호암호화
        bman_celph_tlnum_no_encr: '', // 사업자휴대폰국번번호암호화
        bman_celph_bkno_sno_encr: '', // 사업자휴대폰뒷자리일련번호암호화
        cust_emai_addr_encr: '', // 고객EMAIL주소암호화
        cust_emai_dm_nm: '', // 고객EMAIL도메인명
        cust_jobp_nm: '', // 고객직장명
        jobp_ins_dept_nm: '', // 직장내부부서명
        jobp_addr_pstcd01: '', // 직장주소우편번호01
        jobp_addr_pstcd02: '', // 직장주소우편번호02
        cust_jobp_pstcd_addr: '', // 고객직장우편번호주소
        cust_jobp_dtlad_encr: '', // 고객직장상세주소암호화
        jobp_dstno_encr: '', // 직장지역번호암호화
        jobp_tlnum_no_encr: '', // 직장국번번호암호화
        jobp_bkno_sno_encr: '', // 직장뒷자리일련번호암호화
        jobp_tel_extno_encr: '', // 직장전화내선번호암호화
        fax_dstno_encr: '', // 팩스지역번호암호화
        fax_tlnum_no_encr: '', // 팩스국번번호암호화
        fax_bkno_sno_encr: '', // 팩스뒷자리일련번호암호화
        bfcer_fnc_incm_ntc_mth_cd: '', // 수익증권금융소득통보방법코드
        cstgr_lst_sno: '', // 고객그룹최종일련번호
        cust_ivt_pps_doc2_reg_yn: '', // 고객투자목적서등록여부
        cust_ivt_pps_doc2_reg_eno: '', // 고객투자목적서등록사번
        intrn_cust_id: '', // 인터넷고객ID
        reg_ymd: '', // 등록일자
        cust_bas_note: '', // 고객기본비고
        bfcer_mail_trad_cd: '', // 수익증권우편물수령구분코드
        bfcer_fnd_blnc_ntc_sc_cd: '', // 수익증권펀드잔고통보구분코드
        hom_addr_whl_pstcd: '', // 자택주소전체우편번호
        jobp_addr_whl_pstcd: '', // 직장주소전체우편번호
        hom_addr_stdzt_yn: '', // 자택주소표준화여부
        jobp_addr_stdzt_yn: '', // 직장주소표준화여부
        slnby_cncln_iap_yn: '', // 매매체결알림여부
        bfcer_cust_new_sc_cd: '', // 수익증권고객신규구분코드
        cust_info_sync_yn: '', // 고객정보동기화여부
        hom_bldg_no_encr: '', // 자택건물번호암호화
        hom_adtn_addr_encr: '', // 자택부가주소암호화
        jobp_bldg_no_encr: '', // 직장건물번호암호화
        jobp_adtn_addr_encr: '', // 직장부가주소암호화
        sprtn_edstr_cd: '', // 분리파기코드
        rlnm_no_stnd_birth_mmdd: '', // 실명번호기준생년월일
        crd_int_yn1: '', // 신용정보제공동의1여부
        crd_int_yn2: '', // 마케팅동의여부
        proc_gb: '', // 처리구분
        appoint_cust_no: '', // 지정고객번호
        mkt_cnvs_mth_tel_yn: '', // 마케팅권유방법전화여부
        mkt_cnvs_mth_sms_yn: '', // 마케팅권유방법SMS여부
        mkt_cnvs_mth_email_yn: '', // 마케팅권유방법이메일여부
        mkt_cnvs_mth_pst_yn: '', // 마케팅권유방법우편여부
        mkt_cust_deal_yn: '', // 거래고객여부
        mkt_new_gb: '', // 마케팅신규여부
        crd_endymd: '', // 마케팅동의유효종료일
        crd_term: '', // 마케팅동의유효기간
        crd_strymd: '', // 마케팅동의유효시작일
        message: '', //
        sup_id: '', //
        aprv_sno: '' //
      }
    },

    /******************************************************************************
     * Function명 : fn_OnSearch
     * 설명       : 1차 확인(조회)
     ******************************************************************************/
    fn_OnSearch() {
      // 실명번호 체크
      if (!this.fn_com_res_chk()) {
        return
      }

      // 실명번호종류 체크
      if (!this.fn_rname_chk()) {
        return
      }

      // 실명번호종류가 주민번호일 경우 체크
      if (this.lv_cob_rname_gb.value == '01') {
        this.fn_SetCalBirthDt()
      }

      this.fn_Init('search')
      // 개인고객정보조회
      this.fn_CustSearch()
    },

    /******************************************************************************
     * Function명 : fn_com_res_chk
     * 설명       : 실명번호 체크(공통)
     ******************************************************************************/
    fn_com_res_chk() {
      this.lv_cob_rname_gb.error = false
      this.lv_ed_rname_no.error = false

      if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) != 6 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1)) {
        FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
        this.lv_ed_rname_no.error = true
        this.$refs['ed_rname_no1'].focus()
        return false
      }

      if (this.isMobile && this.isMtrans) {
        if (FSCommUtil.gfn_length(this.lv_masked_val) != 7) {
          this.lv_ed_rname_no.error = true
          //FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          return false
        }
      } else {
        if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2)) {
          FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          this.lv_ed_rname_no.error = true
          this.$refs['ed_rname_no2'].focus()
          return false
        }
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_rname_chk
     * 설명       : 실명번호종류 체크(공통)
     ******************************************************************************/
    fn_rname_chk() {
      // 주민등록번호
      if (this.lv_cob_rname_gb.value == '01') {
        this.lv_cob_inc_earner_gb.value = '111' // 소득자 구분

        // 외국인등록번호
      } else if (this.lv_cob_rname_gb.value == '04') {
        this.lv_cob_inc_earner_gb.value = '131' // 소득자 구분

        // 국내거소신고번호
      } else if (this.lv_cob_rname_gb.value == '05') {
        this.lv_cob_inc_earner_gb.value = '141' // 소득자 구분
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_SetCalBirthDt
     * 설명       : 실명번호종류가 주민번호일 경우 생일 세팅
     ******************************************************************************/
    fn_SetCalBirthDt() {
      let t_birthDt = null
      let t_year = 19
      let t_date = this.lv_ed_rname_no.value1

      // 주민번호가 3이상이면 2000년도 출생
      if (!this.isMobile) {
        if (Number(FSCommUtil.gfn_substr(this.lv_ed_rname_no.value2, 0, 1)) > 2) {
          t_year = 20
        }
      } else {
        if (Number(FSCommUtil.gfn_substr(this.lv_masked_val, 0, 1)) > 2) {
          t_year = 20
        }
      }

      t_birthDt = t_year + t_date
      if (!FSCommUtil.gfn_isNull(t_birthDt)) {
        this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_birthDt)
      }
    },

    /***************************************************************************************
     * Function명 : isCUST_STATE_41
     * 설명       : 고객상태값조회 (사이버창구일원화를 통해 인터넷고객 가입한 고객에대한 상태값을 조회하기위함)
     *****************************************************************************************/
    isCUST_STATE_41(cd) {
      if (cd == '41') {
        let t_popupObj = {
          confirm: false,
          content: '추가정보가 필요한 고객입니다.\n(보험창구에서 등록한 고객)\n추가정보등록 화면으로 이동합니다.'
        }
        this.fn_AlertPopup(0, t_popupObj)
        // this.gfn_getMessage("INFO", "추가정보가 필요한 고객입니다.\n(보험창구에서 등록한 고객)\n추가정보등록 화면으로 이동합니다.");

        // 화면 이동 구현필요
        // var paramObj = { }
        // paramObj.rmvl_srn_id = "CU100000";
        // paramObj.rlnm_cnf_no = this.div_search.form.lv_ed_rname_no;
        // this.gfn_moveMenuId("CU850000", paramObj);

        return false
      } else {
        return true
      }
    },

    /************************* AS-IS 스크립트 끝 *********************************/

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
     ******************************************************************************/
    fn_OpenPopup(type, flag) {
      switch (type) {
        case 0:
          this.pPopup100Obj.btnFlag = flag
          this.$refs.popup100.fn_Open()
          break
        case 1:
          this.$refs.popup103.fn_Open()
          break
        case 2:
          this.$refs.popup104.fn_Open()
          break
        case 3:
          this.$refs.popup105.fn_Open()
          break
        case 4:
          this.$refs.popup106.fn_Open()
          break
        case 5:
          this.$refs.popup107.fn_Open()
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      console.log(pData)
      let t_popupObj = {}

      switch (type) {
        case 0:
          console.log('우편번호 callback...')

          // 자택(HOME), 직장(JOB)
          if (pData.btnFlag == 'HOME') {
            this.lv_home_addr.zipNum = pData.vZipcd_head + pData.vZipcd_tail // 우편번호
            this.lv_home_addr.value1 = pData.vZipcd_ubmyundong // 우편번호주소
            this.lv_home_addr.value2 = pData.v_bldg_no // 상세주소암호화
            this.lv_home_addr.value3 = pData.v_stand_addr // 건물번호
            this.lv_home_addr.value4 = pData.v_adtn_addr // 부가주소
            this.lv_home_addr.standard = pData.v_addr_stdzt_yn == 'Y' ? true : false // 표준화여부
          } else if (pData.btnFlag == 'JOB') {
            this.lv_job_addr.zipNum = pData.vZipcd_head + pData.vZipcd_tail // 우편번호
            this.lv_job_addr.value1 = pData.vZipcd_ubmyundong // 우편번호주소
            this.lv_job_addr.value2 = pData.v_bldg_no // 상세주소암호화
            this.lv_job_addr.value3 = pData.v_stand_addr // 건물번호
            this.lv_job_addr.value4 = pData.v_adtn_addr // 부가주소
            this.lv_job_addr.standard = pData.v_addr_stdzt_yn == 'Y' ? true : false // 표준화여부
          }

          break
        case 1:
          console.log('MDG/CDD정보비교 callback...')
          this.fn_SyncResultInfo(pData)

          break
        case 2:
          console.log('고객정보 callback...')

          break
        case 3:
          console.log('직원번호(직웜검색) callback...')
          this.lv_ed_mgr_empno.error = false
          this.lv_ed_mgr_empno.value = pData.staf_eno
          this.lv_ed_mgr_empnm.value = pData.bfcer_eply_nm

          break
        case 4:
          console.log('마케팅 동의여부 callback...')
          this.lv_marketing_agree.error = false
          if (pData.vNewYn == 'NEW') {
            this.lv_marketing_date.display = true

            this.lv_marketing_date.value1 = FSCommUtil.gfn_strToday(1) // 유효시작일
            this.lv_marketing_date.value2 = pData.end_dt // 유효종료일
            this.lv_marketing_date.value3 = pData.avl_prd // 유효년수

            this.lv_agree_phone = pData.tel_yn // 신용정보 동의여부 > 연락방식 > 전화
            this.lv_agree_sms = pData.sms_yn // 신용정보 동의여부 > 연락방식 > 문자
            this.lv_agree_email = pData.email_yn // 신용정보 동의여부 > 연락방식 > E-mail
            this.lv_agree_post = pData.pst_yn // 신용정보 동의여부 > 연락방식 > 우편
          } else {
            this.lv_marketing_date.display = false

            this.lv_marketing_date.value1 = '' // 유효시작일
            this.lv_marketing_date.value2 = '' // 유효종료일
            this.lv_marketing_date.value3 = '' // 유효년수

            this.lv_marketing_agree.value = 'N'
          }
          break
        case 5:
          console.log('고객ID선택 callback...')

          // 유포탈고객ID다건조회 데이터 sync 후 CDI고객정보조회
          this.fn_SyncUPortal(pData)

          // t_popupObj.confirm = true
          // t_popupObj.confirmFunc = this.fn_SyncUPortal
          // t_popupObj.confirmData = pData
          // t_popupObj.content = '고객명은 보험ERP 기준으로 동기화 됩니다.\n 고객명: ' + pData.fnd_cust_nm + ' \n 보험ID: ' + pData.intrn_cust_id + ' \n 계속 진행하시겠습니까?'
          // this.fn_AlertPopup(0, t_popupObj)
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_ErrorHandler
     * 설명       : 에러 발생시 공통 처리
     ******************************************************************************/
    fn_ErrorHandler(event) {
      this.lv_ed_rname_no.disabled = false
      this.lv_btn_confirm = true

      // 에러처리 팝업 필요확인?
      this.fn_AlertPopup(0, pPopupObj)
    },

    /******************************************************************************
     * Function명 : fn_CustSearch, fn_CustSearchResult
     * 설명       : 개인고객정보조회
     ******************************************************************************/
    fn_CustSearch() {
      console.log('fn_CustSearch...')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570118_S'
      this.eaiCommObj.params = {
        bfcer_incmr_sc_cd: this.lv_cob_inc_earner_gb.value, // 수익증권소득자구분코드, Length : 3
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustSearchResult)
    },

    fn_CustSearchResult(pResultData, pErrorCode) {
      console.log('fn_CustSearchResult...')
      let t_data = pResultData.data.cu100000_O_01VO
      console.log(t_data)

      if (t_data.length > 0) {
        if (t_data[0].rt_cd === '41') {
          if (!this.isCUST_STATE_41('41')) {
            return
          }

          // 활성/비활성화 진행 안되는거 같아 제거예정
          // this.lv_ed_rname_no.set_enable(true)
          // this.btn_confirm.set_enable(false)
        } else {
          this.pPopup104Obj.custSearchList = t_data

          // 고객정보 팝업
          this.fn_OpenPopup(2)

          // 다음버튼 활성화 및 데이터 매핑
          this.lv_btn_next.disabled = false
          this.lv_btn_next.value = [this.lv_ed_rname_no.value1, this.lv_ed_rname_no.value2]

          // 실명번호 입력 초기화
          if (!this.isMobile) {
            this.lv_ed_rname_no.value1 = ''
            this.lv_ed_rname_no.value2 = ''
          } else {
            this.lv_isClear = !this.lv_isClear ? true : false
          }
          return
        }
      } else {
        // 실명번호 입력 비활성화
        this.lv_ed_rname_no.disabled = true
      }

      // CDD고객조회
      this.fn_CddCustSearch()

      // 동기화여부
      this.lv_chk_cdi_sync_yn = 1
    },

    /******************************************************************************
     * Function명 : fn_CddCustSearch, fn_CddCustSearchResult
     * 설명       : CDD고객조회
     ******************************************************************************/
    fn_CddCustSearch() {
      console.log('fn_CddCustSearch....')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: '', // 수익증권 계좌번호, Length : 13
        rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 실명확인번호구분코드, Length : 10
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2, // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
        fnd_cust_nm: 'TEST' // 펀드고객명, Length : 100 //// AS-IS 이름필수인데 알수없어 아무거나 넣음(cdi 인터페이스호출시 이름 필수인데 없으면 에러나서)
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CddCustSearchResult)
    },
    fn_CddCustSearchResult(pResultData) {
      console.log('fn_CddCustSearchResult....')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      // 고객명 복사
      this.lv_cdd_cust_nm = FSCommUtil.gfn_trim(t_data.cust_cnf_syst_cust_nm)

      if (FSCommUtil.gfn_isNull(this.lv_cdd_cust_nm)) {
        // CDD고객정보 없음
        t_popupObj.content = 'CDD고객정보가 존재하지 않습니다.'
        this.fn_AlertPopup(0, t_popupObj)

        // 실명번호 입력 활성화
        this.lv_ed_rname_no.disabled = false
        // 주연락처
        this.lv_main_dm.value = '1'
      } else {
        let v_h_addr_zipcode3 = t_data.hom_addr_pstcd03
        let v_o_addr_zipcode3 = t_data.jobp_addr_pstcd03
        let v_h_addr_stdzt_yn = t_data.hom_addr_stdzt_yn
        let v_o_addr_stdzt_yn = t_data.jobp_addr_stdzt_yn

        t_data.hom_addr_pstcd03 = ''
        t_data.jobp_addr_pstcd03 = ''
        t_data.hom_addr_stdzt_yn = ''
        t_data.jobp_addr_stdzt_yn = ''

        // 주연락처 확인 자택('1') / 직장('2')
        if (t_data.bfcer_mn_ctadr_addr_cd == '2') {
          // 직장
          let v_h_addr_zipcode1 = t_data.hom_addr_pstcd01
          let v_h_addr_zipcode2 = t_data.hom_addr_pstcd02
          let v_h_zip_addr = t_data.cust_hom_pstcd_addr
          let v_h_remn_addr = t_data.cust_hom_dtlad_encr
          let v_h_telno_area = t_data.hom_dstno_encr
          let v_h_telno_station = t_data.hom_tlnum_no_encr
          let v_h_telno_seq = t_data.hom_bkno_sno_encr
          let v_h_bldg_no = t_data.hom_bldg_no_encr
          let v_h_adtn_addr = t_data.hom_adtn_addr_encr

          t_data.jobp_bldg_no_encr = v_h_bldg_no
          t_data.jobp_adtn_addr_encr = v_h_adtn_addr
          t_data.hom_bldg_no_encr = ''
          t_data.hom_adtn_addr_encr = ''

          t_data.jobp_addr_pstcd01 = v_h_addr_zipcode1
          t_data.jobp_addr_pstcd02 = v_h_addr_zipcode2
          t_data.jobp_addr_pstcd03 = v_h_addr_zipcode3
          t_data.cust_jobp_pstcd_addr = v_h_zip_addr
          t_data.cust_jobp_dtlad_encr = v_h_remn_addr

          t_data.jobp_dstno_encr = v_h_telno_area
          t_data.jobp_tlnum_no_encr = v_h_telno_station
          t_data.jobp_bkno_sno_encr = v_h_telno_seq

          t_data.jobp_addr_stdzt_yn = v_h_addr_stdzt_yn

          t_data.hom_addr_pstcd01 = ''
          t_data.hom_addr_pstcd02 = ''
          t_data.hom_addr_pstcd03 = ''
          t_data.cust_hom_pstcd_addr = ''
          t_data.cust_hom_dtlad_encr = ''
          t_data.hom_dstno_encr = ''
          t_data.hom_tlnum_no_encr = ''
          t_data.hom_bkno_sno_encr = ''
          t_data.hom_addr_stdzt_yn = ''
        } else {
          // 자택
          let v_h_addr_zipcode1 = t_data.hom_addr_pstcd01
          let v_h_addr_zipcode2 = t_data.hom_addr_pstcd02
          let v_h_zip_addr = t_data.cust_hom_pstcd_addr
          let v_h_remn_addr = t_data.cust_hom_dtlad_encr
          let v_h_telno_area = t_data.hom_dstno_encr
          let v_h_telno_station = t_data.hom_tlnum_no_encr
          let v_h_telno_seq = t_data.hom_bkno_sno_encr
          let v_h_bldg_no = t_data.hom_bldg_no_encr
          let v_h_adtn_addr = t_data.hom_adtn_addr_encr

          t_data.hom_bldg_no_encr = v_h_bldg_no
          t_data.hom_adtn_addr_encr = v_h_adtn_addr
          t_data.jobp_bldg_no_encr = ''
          t_data.jobp_adtn_addr_encr = ''

          t_data.hom_addr_pstcd01 = v_h_addr_zipcode1
          t_data.hom_addr_pstcd02 = v_h_addr_zipcode2
          t_data.cust_hom_pstcd_addr = v_h_zip_addr
          t_data.cust_hom_dtlad_encr = v_h_remn_addr

          t_data.hom_dstno_encr = v_h_telno_area
          t_data.hom_tlnum_no_encr = v_h_telno_station
          t_data.hom_bkno_sno_encr = v_h_telno_seq

          t_data.hom_addr_stdzt_yn = v_h_addr_stdzt_yn

          t_data.jobp_addr_pstcd01 = ''
          t_data.jobp_addr_pstcd02 = ''
          t_data.jobp_addr_pstcd03 = ''
          t_data.cust_jobp_pstcd_addr = ''
          t_data.cust_jobp_dtlad_encr = ''
          t_data.jobp_dstno_encr = ''
          t_data.jobp_tlnum_no_encr = ''
          t_data.jobp_bkno_sno_encr = ''
          t_data.jobp_addr_stdzt_yn = ''
        }

        // CDD고객정보 복사
        this.lv_cddCustObj = t_data

        this.lv_cddCustObj.resi_yn = 'Y'
        this.lv_cddCustObj.crd_int_yn1 = 'Y'
        this.lv_cddCustObj.crd_int_yn2 = ''
        this.lv_cddCustObj.birth_solun_gb = '01'

        if (this.lv_cob_rname_gb.value == '05') {
          if (FSCommUtil.gfn_isNull(t_data.cust_cnf_syst_birth_mmdd)) {
            this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_data.cust_cnf_syst_birth_mmdd)
          }
        }

        // 실명번호종류가 6일 경우 값 세팅 - 여권번호 사용안함(불필요)
        // if (this.lv_cob_rname_gb.value == '6') {
        //   this.lv_cob_inc_earner_gb.value = '112'       // 소득자구분 - 확인필요
        //   this.lv_cob_rname_gb.disabled = true  // 수익증권실명번호종류 비활성화
        // }

        // 고객의 보험ID 다건 조회(유포탈고객ID다건조회)
        this.fn_UPortalCustSearch()
      }
    },

    /******************************************************************************
     * Function명 : fn_UPortalCustSearch, fn_UPortalCustSearchResult, fn_SyncUPortal
     * 설명       : 유포탈고객ID다건조회
     ******************************************************************************/
    fn_UPortalCustSearch() {
      console.log('fn_UPortalCustSearch....')

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570119_S'
      this.eaiCommObj.params = {
        bfcer_incmr_sc_cd: this.lv_cob_inc_earner_gb.value, // 수익증권소득자구분코드, Length : 3
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        fnd_cust_nm: '', // 펀드고객명, Length : 100
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_UPortalCustSearchResult)
    },

    fn_UPortalCustSearchResult(pResultData) {
      console.log('fn_UPortalCustSearchResult....')

      let t_popupObj = {}
      let t_dataList = []
      let t_data = pResultData.data.eaf1MDI0169_I_VO
      console.log(t_data)

      if (t_data.length > 1) {
        // 팝업 데이터 세팅
        t_data.forEach((item, idx) => {
          t_dataList.push({ intrn_cust_id: item.custId, rlnm_cnf_no_encr: item.rrn, fnd_cust_nm: item.custNm, cust_birth_mmdd: item.dob })
        })
        t_dataList[0]['fnd_cust_nm_ex'] = this.lv_cdd_cust_nm // CDD고객명

        this.pPopup107Obj.custSearchList = t_dataList

        // 고객ID선택 팝업
        this.fn_OpenPopup(5)

        // 해당 실명번호로 보유한 보험ID가 다건일 경우
        t_popupObj.content = '삼성생명에 해당 실명번호의 고객이 다 건 존재합니다.'
        this.fn_AlertPopup(0, t_popupObj)
        return
      } else if (t_data.length == 1) {
        // CDD고객명이랑 CDI고객명 다른 경우
        if (this.lv_cdd_cust_nm != t_data[0].custNm) {
          // 팝업 데이터 세팅
          t_dataList.push({ fnd_cust_nm_ex: this.lv_cdd_cust_nm, intrn_cust_id: t_data[0].custId, rlnm_cnf_no_encr: t_data[0].rrn, fnd_cust_nm: t_data[0].custNm, cust_birth_mmdd: t_data[0].dob })
          this.pPopup107Obj.custSearchList = t_dataList

          // 고객ID선택 팝업
          this.fn_OpenPopup(5)

          t_popupObj.content = '삼성생명에 상이한 고객명으로 기등록되어 있습니다.'
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
      }

      // 유포탈고객ID다건조회 결과 없을 경우 CDI고객정보조회
      this.fn_CdiCustSearch()
    },

    fn_SyncUPortal(pData) {
      console.log('fn_SyncUPortal....')

      // 동기화 후 팝업 종료
      this.lv_uportalObj = pData
      this.$refs.popup107.fn_Close()

      // CDI고객정보조회
      this.fn_CdiCustSearch()
    },

    /******************************************************************************
     * Function명 : fn_CdiCustSearch, fn_CdiCustSearchResult
     * 설명       : CDI고객정보조회
     ******************************************************************************/
    fn_CdiCustSearch() {
      console.log('fn_CdiCustSearch....')

      this.lv_chk_cdi_sync_yn = 1 // 동기화여부

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570120_S'
      this.eaiCommObj.params = {
        bfcer_incmr_sc_cd: this.lv_cob_inc_earner_gb.value, // 수익증권소득자구분코드, Length : 3
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        fnd_cust_nm: this.lv_uportalObj.fnd_cust_nm, // 펀드고객명, Length : 100
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CdiCustSearchResult)
    },
    fn_CdiCustSearchResult(pResultData) {
      console.log('fn_CdiCustSearchResult....')

      this.lv_cdiInfoObj = pResultData.data

      let t_cdiInfo = this.lv_cdiInfoObj.cu100000_O_00VO // CDI고객정보
      this.lv_cdi_bp_data = this.lv_cdiInfoObj.cu100000_O_06VO // CDI BP정보
      this.lv_cdi_pass_data = this.lv_cdiInfoObj.cu100000_O_07VO // 여권정보
      this.lv_cdi_addr_data = this.lv_cdiInfoObj.cu100000_O_08VO // 주소정보
      this.lv_cdi_mka_data = this.lv_cdiInfoObj.cu100000_O_10VO // 마케팅동의정보
      console.log(pResultData.data)

      // CDI고객정보가 있을 경우
      if (FSCommUtil.gfn_length(t_cdiInfo) > 0) {
        // CDI BP정보에서 partner 확인
        if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(this.lv_cdi_bp_data[0].partner)) > 0) {
          // cdi측에 데이터 존재하는 경우, 등록 수정일 경우
          // this.ds_cu1000_bfc.copyData(this.ds_cu1000_1); //인디케이터값 설정을 위해 복사

          this.lv_ed_rname_no.disabled = true // 실명번호 비활성화

          this.pPopup103Obj = {
            pCddInfo: this.lv_cddCustObj,
            pCdiInfo: t_cdiInfo[0]
          }

          // MDG/CDD정보비교 팝업
          setTimeout(() => {
            this.fn_OpenPopup(1)
          }, 100)
        } else {
          // cdi측에 데이터가 존재하지 않는 경우, 신규 등록일 경우
          this.fn_SyncResultInfo('NEW')
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_SyncResultInfo
     * 설명       : MDG/CDD정보비교 팝업에서 선택값(CDD/CDI) 기준으로 데이터 매핑
     ******************************************************************************/
    fn_SyncResultInfo(pData) {
      console.log('fn_SyncResultInfo....')

      let t_popupObj = {}
      let t_cddInfo = this.lv_cddCustObj
      let t_cdiInfo = this.lv_cdiInfoObj.cu100000_O_00VO[0]

      // 화면에 사용되는 data 매핑
      let t_setData = pData == 'CDI' ? this.lv_cdiInfoObj.cu100000_O_00VO[0] : this.lv_cddCustObj
      // //수정시작
      // if(this.lv_cdiInfoObj.cu100000_O_00VO.legnth > 0){
      // 등록시 사용되는 submit dataset 매핑
      if (pData == 'CDI') {
        // CDI고객정보 매핑
        Object.assign(this.lv_submit_data, t_setData)
      } else {
        // CDD고객정보 매핑
        this.fn_CddInfoCopy()
      }
      this.lv_submit_data.crd_int_yn1 = 'Y' // 신용정보동의여부 - 계약체결

      if (pData == 'CDD' || pData == 'CDI') {
        console.log('sync flag11 ::: ' + pData)

        if (pData == 'CDI') {
          this.lv_fax_no.value1 = t_cdiInfo.fax_dstno_encr
          this.lv_fax_no.value2 = t_cdiInfo.fax_tlnum_no_encr
          this.lv_fax_no.value3 = t_cdiInfo.fax_bkno_sno_encr
        }
        this.lv_cust_nm.value = t_cdiInfo.fnd_cust_nm // 성명(이름은 항상 CDI(통합고객) 것을 따를 것)

        this.lv_cob_inc_earner_gb.value = '' //t_setData.bfcer_incmr_sc_cd       // 소득자구분  일단 빈값으로 처리
        this.lv_lvht_sc_cd.value = FSCommUtil.gfn_isNull(t_setData.bfcer_lvht_sc_cd) === true ? '0' : t_setData.bfcer_lvht_sc_cd // 생계형구분

        this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_cdiInfo.cust_birth_mmdd) // 생일(생년월일)
        this.lv_birth_solun_gb.value = t_cdiInfo.bfcer_slrc_lnrc_sc_cd // 양력음력구분

        // CDD고객정보와 CDI고객정보일때 국적/거주국가 컬럼 틀림
        if (pData == 'CDI') {
          this.lv_cust_eng_nm.value = t_cdiInfo.fnd_cust_eng_nm // 고객영문명

          this.lv_naty_cntry_cd.value = t_setData.bfcer_naty_cntry_cd // 국적
          this.lv_fnd_dmcl_cntry_cd.value = t_setData.fnd_dmcl_cntry_cd // 거주국가
          this.lv_dwlr_yn.value = t_setData.dwlr_yn // 거주자여부
        } else {
          this.lv_cust_eng_nm.value = t_setData.cust_cnf_syst_cust_eng_nm // 고객영문명

          this.lv_naty_cntry_cd.value = t_setData.fnd_dmcl_cntry_cd // 국적
          this.lv_fnd_dmcl_cntry_cd.value = t_cdiInfo.fnd_dmcl_cntry_cd // 거주국가
          this.lv_dwlr_yn.value = '' // 거주자여부
        }

        // 마케팅동의여부
        this.lv_marketing_agree.value = t_cdiInfo.crd_int_yn2
        this.lv_marketing_chk = t_cdiInfo.crd_int_yn2
        // 마케팅동의여부 'Y' 경우
        if (t_cdiInfo.crd_int_yn2 == 'Y') {
          this.lv_agree_phone = t_cdiInfo.mkt_cnvs_mth_tel_yn == 'Y' ? true : false // 신용정보 동의여부 > 연락방식 > 전화
          this.lv_agree_sms = t_cdiInfo.mkt_cnvs_mth_sms_yn == 'Y' ? true : false // 신용정보 동의여부 > 연락방식 > 문자
          this.lv_agree_email = t_cdiInfo.mkt_cnvs_mth_email_yn == 'Y' ? true : false // 신용정보 동의여부 > 연락방식 > E-mail
          this.lv_agree_post = t_cdiInfo.mkt_cnvs_mth_pst_yn == 'Y' ? true : false // 신용정보 동의여부 > 연락방식 > 우편

          let crd_strymd1 = t_cdiInfo.crd_strymd.replace('-', '')
          let crd_strymd2 = FSCommUtil.gfn_substr(crd_strymd1, 0, 8)

          // 마케팅동의 시작일자
          this.lv_marketing_date.value1 = crd_strymd2

          if (FSCommUtil.gfn_length(t_cdiInfo.crd_endymd) >= 8) {
            let crd_endymd1 = t_cdiInfo.crd_endymd.replace('-', '')
            let crd_endymd2 = FSCommUtil.gfn_substr(crd_endymd1, 0, 8)

            // 마케팅동의 종료일자 표시
            this.lv_marketing_date.value2 = FSCommUtil.gfn_dateReplace(1, crd_endymd2)
            this.lv_marketing_date.display = true

            // 마케팅동의 유효기간
            let crd_term
            if (crd_endymd2 == '99991231') {
              crd_term = '99'
            } else {
              crd_term = Number(FSCommUtil.gfn_substr(crd_endymd2, 0, 4)) - Number(FSCommUtil.gfn_substr(crd_strymd2, 0, 4))
            }
            this.lv_marketing_date.value3 = crd_term
          }

          // 마케팅 동의여부 비활성화
          this.lv_marketing_agree.disabled = true
        }

        // 주연락처(자택:1, 직장:2)
        this.lv_main_dm.value = t_setData.bfcer_mn_ctadr_addr_cd

        if (t_setData.bfcer_mn_ctadr_addr_cd == '1') {
          // 자택
          this.lv_mail_trad_cd.value = '11' //우편물통보처 자택

          // 주소
          this.lv_home_addr.zipNum = t_setData.hom_addr_pstcd01 + t_setData.hom_addr_pstcd02 // 우편번호
          this.lv_home_addr.value1 = t_setData.cust_hom_pstcd_addr // 우편번호주소
          this.lv_home_addr.value2 = t_setData.hom_bldg_no_encr // 상세주소암호화
          this.lv_home_addr.value3 = t_setData.cust_hom_dtlad_encr // 건물번호
          this.lv_home_addr.value4 = t_setData.hom_adtn_addr_encr // 부가주소

          // 주소표준화여부
          this.lv_home_addr.standard = t_setData.hom_addr_stdzt_yn == 'Y' ? true : false

          // 전화번호
          this.lv_home_no.value1 = t_setData.hom_dstno_encr
          this.lv_home_no.value2 = t_setData.hom_tlnum_no_encr
          this.lv_home_no.value3 = t_setData.hom_bkno_sno_encr
        } else if (t_setData.bfcer_mn_ctadr_addr_cd == '2') {
          // 직장
          this.lv_mail_trad_cd.value = '12' //우편물통보처 직장

          // 주소
          this.lv_job_addr.zipNum = t_setData.jobp_addr_pstcd01 + t_setData.jobp_addr_pstcd02 // 우편번호
          this.lv_job_addr.value1 = t_setData.cust_jobp_pstcd_addr // 우편번호주소
          this.lv_job_addr.value2 = t_setData.jobp_bldg_no_encr // 상세주소암호화
          this.lv_job_addr.value3 = t_setData.cust_jobp_dtlad_encr // 건물번호
          this.lv_job_addr.value4 = t_setData.jobp_adtn_addr_encr // 부가주소

          // 주소표준화여부
          this.lv_job_addr.standard = t_setData.jobp_addr_stdzt_yn == 'Y' ? true : false

          // 전화번호
          this.lv_job_no.value1 = t_setData.jobp_dstno_encr
          this.lv_job_no.value2 = t_setData.jobp_tlnum_no_encr
          this.lv_job_no.value3 = t_setData.jobp_bkno_sno_encr
        } else {
          // ERP(CDI) 선택시 주연락처(bfcer_mn_ctadr_addr_cd) 값이 없는 경우
          if (pData == 'CDI') {
            // 자택주소
            this.lv_home_addr.zipNum = t_setData.hom_addr_pstcd01 + t_setData.hom_addr_pstcd02 // 우편번호
            this.lv_home_addr.value1 = t_setData.cust_hom_pstcd_addr // 우편번호주소
            this.lv_home_addr.value2 = t_setData.hom_bldg_no_encr // 상세주소암호화
            this.lv_home_addr.value3 = t_setData.cust_hom_dtlad_encr // 건물번호
            this.lv_home_addr.value4 = t_setData.hom_adtn_addr_encr // 부가주소

            // 주소표준화여부
            this.lv_home_addr.standard = t_setData.hom_addr_stdzt_yn == 'Y' ? true : false

            // 전화번호
            this.lv_home_no.value1 = t_setData.hom_dstno_encr
            this.lv_home_no.value2 = t_setData.hom_tlnum_no_encr
            this.lv_home_no.value3 = t_setData.hom_bkno_sno_encr

            // 직장주소
            this.lv_job_addr.zipNum = t_setData.jobp_addr_pstcd01 + t_setData.jobp_addr_pstcd02 // 우편번호
            this.lv_job_addr.value1 = t_setData.cust_jobp_pstcd_addr // 우편번호주소
            this.lv_job_addr.value2 = t_setData.jobp_bldg_no_encr // 상세주소암호화
            this.lv_job_addr.value3 = t_setData.cust_jobp_dtlad_encr // 건물번호
            this.lv_job_addr.value4 = t_setData.jobp_adtn_addr_encr // 부가주소

            // 주소표준화여부
            this.lv_job_addr.standard = t_setData.jobp_addr_stdzt_yn == 'Y' ? true : false

            // 전화번호
            this.lv_job_no.value1 = t_setData.jobp_dstno_encr
            this.lv_job_no.value2 = t_setData.jobp_tlnum_no_encr
            this.lv_job_no.value3 = t_setData.jobp_bkno_sno_encr
          }
        }

        this.lv_job_name.value = pData == 'CDD' ? FSCommUtil.gfn_trim(t_setData.cust_cnf_syst_co_nm) : FSCommUtil.gfn_trim(t_setData.cust_jobp_nm) // 직장명
        this.lv_dept_name.value = pData == 'CDD' ? t_setData.cust_cnf_syst_dept_nm : t_setData.jobp_ins_dept_nm // 부서명

        // 휴대폰
        this.lv_phone_no.value1 = t_setData.bman_celph_dstno_encr
        this.lv_phone_no.value2 = t_setData.bman_celph_tlnum_no_encr
        this.lv_phone_no.value3 = t_setData.bman_celph_bkno_sno_encr

        // email
        this.lv_email_addr.value1 = t_setData.cust_emai_addr_encr
        this.lv_email_addr.value2 = t_setData.cust_emai_dm_nm

        // 성명 변경 못하도록 비활성화
        this.lv_cust_nm.disabled = true
      } else {
        console.log('sync flag22 ::: ' + pData)

        // cdi측에 데이터가 존재하지 않는 경우, 신규 등록일 경우
        t_popupObj.content = '삼성생명(CDI)에 등록되지 않은 고객입니다. 등록하시기 바랍니다.'
        this.fn_AlertPopup(0, t_popupObj)

        //   this.ds_cu1000_copy();

        // 성명
        this.lv_cust_nm.value = t_setData.cust_cnf_syst_cust_nm
        // 고객영문명
        this.lv_cust_eng_nm.value = t_setData.cust_cnf_syst_cust_eng_nm

        this.lv_cob_inc_earner_gb.value = '111' // 소득자구분
        this.lv_lvht_sc_cd.value = '0' // 생계형구분
        this.lv_naty_cntry_cd.value = t_setData.fnd_dmcl_cntry_cd // 국적
        this.lv_fnd_dmcl_cntry_cd.value = t_setData.bfcer_dmcl_cntry_cd // 거주국가

        // 마케팅동의 시작일자
        this.lv_marketing_date.value1 = ''

        // 주연락처(자택:1, 직장:2)
        this.lv_main_dm.value = t_setData.bfcer_mn_ctadr_addr_cd
        this.lv_mail_trad_cd.value = '15' //우편물통보처 스마트

        if (t_setData.bfcer_mn_ctadr_addr_cd == '1') {
          // 자택
          // this.lv_mail_trad_cd.value    = '11'  //우편물통보처 자택

          // 주소
          this.lv_home_addr.zipNum = t_setData.hom_addr_pstcd01 + t_setData.hom_addr_pstcd02 // 우편번호
          this.lv_home_addr.value1 = t_setData.cust_hom_pstcd_addr // 우편번호주소
          this.lv_home_addr.value2 = t_setData.hom_bldg_no_encr // 상세주소암호화
          this.lv_home_addr.value3 = t_setData.cust_hom_dtlad_encr // 건물번호
          this.lv_home_addr.value4 = t_setData.hom_adtn_addr_encr // 부가주소

          // 주소표준화여부
          this.lv_home_addr.standard = t_setData.hom_addr_stdzt_yn == 'Y' ? true : false

          // 전화번호
          this.lv_home_no.value1 = t_setData.hom_dstno_encr
          this.lv_home_no.value2 = t_setData.hom_tlnum_no_encr
          this.lv_home_no.value3 = t_setData.hom_bkno_sno_encr
        } else if (t_setData.bfcer_mn_ctadr_addr_cd == '2') {
          // 직장
          // this.lv_mail_trad_cd.value    = '12'  //우편물통보처 직장

          // 주소
          this.lv_job_addr.zipNum = t_setData.jobp_addr_pstcd01 + t_setData.jobp_addr_pstcd02 // 우편번호
          this.lv_job_addr.value1 = t_setData.cust_jobp_pstcd_addr // 우편번호주소
          this.lv_job_addr.value2 = t_setData.jobp_bldg_no_encr // 상세주소암호화
          this.lv_job_addr.value3 = t_setData.cust_jobp_dtlad_encr // 건물번호
          this.lv_job_addr.value4 = t_setData.jobp_adtn_addr_encr // 부가주소

          // 주소표준화여부
          this.lv_job_addr.standard = t_setData.jobp_addr_stdzt_yn == 'Y' ? true : false

          // 전화번호
          this.lv_job_no.value1 = t_setData.jobp_dstno_encr
          this.lv_job_no.value2 = t_setData.jobp_tlnum_no_encr
          this.lv_job_no.value3 = t_setData.jobp_bkno_sno_encr
        }

        this.lv_job_name.value = FSCommUtil.gfn_trim(t_setData.cust_cnf_syst_co_nm) // 직장명
        this.lv_dept_name.value = t_setData.jobp_ins_dept_nm // 부서명

        // 휴대폰
        this.lv_phone_no.value1 = t_setData.bman_celph_dstno_encr
        this.lv_phone_no.value2 = t_setData.bman_celph_tlnum_no_encr
        this.lv_phone_no.value3 = t_setData.bman_celph_bkno_sno_encr

        // email
        this.lv_email_addr.value1 = t_setData.cust_emai_addr_encr
        this.lv_email_addr.value2 = t_setData.cust_emai_dm_nm
      }

      // }// if end
      // this.lv_submit_data.crd_int_yn1 = 'Y' // 신용정보동의여부 - 계약체결
      // this.lv_cust_nm.value             = t_cdiInfo.fnd_cust_nm //고객명
      // this.lv_cust_nm.value             = t_cdiInfo.cust_jobp_nm //직장명
      // this.lv_cust_nm.value             = t_cdiInfo.jobp_ins_dept_nm //부서명
      // this.lv_cust_nm.value             = t_cdiInfo.hom_adtn_addr_encr //자택부가주소
      // this.lv_cust_nm.value             = t_cdiInfo.jobp_adtn_addr_encr //직장부가주소

      this.lv_credit_agree.value = 'Y' // 신용정보 동의여부 > 계약체결

      // 등록 버튼 활성화
      this.lv_btn_confirm = false
    },

    /******************************************************************************
     * Function명 : fn_CddInfoCopy
     * 설명       : CDD고객정보 submit dataset 매핑
     ******************************************************************************/
    fn_CddInfoCopy() {
      console.log('fn_CddInfoCopy....')

      this.lv_submit_data.bfcer_rlnm_no_knd_cd = this.lv_cddCustObj.bfcer_rlnm_no_knd_cd //수익증권실명번호종류코드
      this.lv_submit_data.rlnm_cnf_no_encr = this.lv_cddCustObj.rlnm_cnf_no_encr //실명확인번호암호화
      this.lv_submit_data.corp_reg_no = this.lv_cddCustObj.corp_reg_no //법인등록번호
      this.lv_submit_data.fnd_cust_nm = this.lv_cddCustObj.cust_cnf_syst_cust_nm //펀드고객명
      this.lv_submit_data.bfcer_cust_stat_cd = this.lv_cddCustObj.bfcer_cust_stat_cd //수익증권고객상태코드
      this.lv_submit_data.fnd_cust_eng_nm = this.lv_cddCustObj.cust_cnf_syst_cust_eng_nm //펀드고객영문명
      this.lv_submit_data.bfcer_resnt_nm = this.lv_cddCustObj.cust_cnf_syst_resnt_nm //수익증권대표자명
      this.lv_submit_data.bfcer_naty_cntry_cd = this.lv_cddCustObj.fnd_dmcl_cntry_cd //수익증권국적국가코드
      this.lv_submit_data.fnd_dmcl_cntry_cd = this.lv_cddCustObj.bfcer_dmcl_cntry_cd //펀드거주지국가코드
      this.lv_submit_data.cust_birth_mmdd = this.lv_cddCustObj.cust_cnf_syst_birth_mmdd //고객생년월일
      this.lv_submit_data.bfcer_mn_ctadr_addr_cd = this.lv_cddCustObj.bfcer_mn_ctadr_addr_cd //수익증권주연락처주소코드
      this.lv_submit_data.hom_addr_pstcd01 = this.lv_cddCustObj.hom_addr_pstcd01 //자택주소우편번호01
      this.lv_submit_data.hom_addr_pstcd02 = this.lv_cddCustObj.hom_addr_pstcd02 //자택주소우편번호02
      this.lv_submit_data.cust_hom_pstcd_addr = this.lv_cddCustObj.cust_hom_pstcd_addr //고객자택우편번호주소
      this.lv_submit_data.cust_hom_dtlad_encr = this.lv_cddCustObj.cust_hom_dtlad_encr //고객자택상세주소암호화
      this.lv_submit_data.hom_dstno_encr = this.lv_cddCustObj.hom_dstno_encr //자택지역번호암호화
      this.lv_submit_data.hom_tlnum_no_encr = this.lv_cddCustObj.hom_tlnum_no_encr //자택국번번호암호화
      this.lv_submit_data.hom_bkno_sno_encr = this.lv_cddCustObj.hom_bkno_sno_encr //자택뒷자리일련번호암호화
      this.lv_submit_data.bman_celph_dstno_encr = this.lv_cddCustObj.bman_celph_dstno_encr //사업자휴대폰지역번호암호화"
      this.lv_submit_data.bman_celph_tlnum_no_encr = this.lv_cddCustObj.bman_celph_tlnum_no_encr //사업자휴대폰국번번호암호화"
      this.lv_submit_data.bman_celph_bkno_sno_encr = this.lv_cddCustObj.bman_celph_bkno_sno_encr //사업자휴대폰뒷자리일련번호암호화"
      this.lv_submit_data.cust_emai_addr_encr = this.lv_cddCustObj.cust_emai_addr_encr //고객EMAIL주소암호화
      this.lv_submit_data.cust_emai_dm_nm = this.lv_cddCustObj.cust_emai_dm_nm //고객EMAIL도메인명
      this.lv_submit_data.cust_jobp_nm = this.lv_cddCustObj.cust_cnf_syst_co_nm //고객직장명
      this.lv_submit_data.jobp_ins_dept_nm = this.lv_cddCustObj.cust_cnf_syst_dept_nm //직장내부부서명
      this.lv_submit_data.jobp_addr_pstcd01 = this.lv_cddCustObj.jobp_addr_pstcd01 //직장주소우편번호01
      this.lv_submit_data.jobp_addr_pstcd02 = this.lv_cddCustObj.jobp_addr_pstcd02 //직장주소우편번호02
      this.lv_submit_data.cust_jobp_pstcd_addr = this.lv_cddCustObj.cust_jobp_pstcd_addr //고객직장우편번호주소
      this.lv_submit_data.cust_jobp_dtlad_encr = this.lv_cddCustObj.cust_jobp_dtlad_encr //고객직장상세주소암호화
      this.lv_submit_data.jobp_dstno_encr = this.lv_cddCustObj.jobp_dstno_encr //직장지역번호암호화
      this.lv_submit_data.jobp_tlnum_no_encr = this.lv_cddCustObj.jobp_tlnum_no_encr //직장국번번호암호화
      this.lv_submit_data.jobp_bkno_sno_encr = this.lv_cddCustObj.jobp_bkno_sno_encr //직장뒷자리일련번호암호화
      this.lv_submit_data.hom_addr_whl_pstcd = this.lv_cddCustObj.hom_addr_pstcd03 //자택주소전체우편번호
      this.lv_submit_data.jobp_addr_whl_pstcd = this.lv_cddCustObj.jobp_addr_pstcd03 //직장주소전체우편번호
      this.lv_submit_data.hom_addr_stdzt_yn = this.lv_cddCustObj.hom_addr_stdzt_yn //자택주소표준화여부
      this.lv_submit_data.jobp_addr_stdzt_yn = this.lv_cddCustObj.jobp_addr_stdzt_yn //직장주소표준화여부
      this.lv_submit_data.bfcer_cust_new_sc_cd = this.lv_cddCustObj.bfcer_cust_new_sc_cd //수익증권고객신규구분코드
      this.lv_submit_data.hom_bldg_no_encr = this.lv_cddCustObj.hom_bldg_no_encr //자택건물번호암호화
      this.lv_submit_data.hom_adtn_addr_encr = this.lv_cddCustObj.hom_adtn_addr_encr //자택부가주소암호화
      this.lv_submit_data.jobp_bldg_no_encr = this.lv_cddCustObj.jobp_bldg_no_encr //직장건물번호암호화
      this.lv_submit_data.jobp_adtn_addr_encr = this.lv_cddCustObj.jobp_adtn_addr_encr //직장부가주소암호화
      this.lv_submit_data.rlnm_no_stnd_birth_mmdd = this.lv_cddCustObj.cust_cnf_syst_birth_mmdd //실명번호기준생년월일
    },

    /******************************************************************************
     * Function명 : fn_CustSubmit
     * 설명       : 개인고객정보등록
     ******************************************************************************/
    async fn_CustSubmit() {
      console.log('fn_CustSubmit....')

      // 실명번호 체크
      if (!this.fn_com_res_chk()) {
        return
      }
      // ASR240701140 / 고객명 띄어쓰기 허용
      var pObj = { objName: 'lv_cust_nm', keyName: 'value' }
      if (!this.fn_Keyup('', pObj, 'ssnf', 'I')) {
        return
      }

      // 등록시 필수값 체크
      if (!this.fn_SubmitVaild()) {
        return
      }

      // 등록시 값에 따른 체크
      if (!this.fn_rname_chk02()) {
        return
      }

      // 등록시 매핑
      this.fn_SetSubmitData()
      // ASR240200203 / [Sub]사랑On3.0_수익증권 CDD/고객등록 커버발행이력 생성 로직 보완
      if (await FSInfoUtil.preCvrNoDel(this)) {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570121_S'
        this.eaiCommObj.params = this.lv_submit_data

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustSubmitResult)
      }
    },

    fn_CustSubmitResult(pResultData) {
      console.log('fn_CustSubmitResult....')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log('t_data>>>>', t_data)

      if (t_data.error_msg == '809901') {
        this.lv_cust_no = t_data.bfcer_cust_no
        let t_type = 0
        if (this.lv_isProcess) {
          t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: this.fn_ReportPrint,
            confirmData: {
              bfcer_cust_no: this.lv_cust_no
            },
            content: '고객등록이 완료되었습니다. 설문등록 화면으로 이동합니다.'
          }
        } else {
          t_type = 9
          t_popupObj = {
            confirm: true,
            confirmFunc: this.fn_ReportPrint,
            confirmData: {
              bfcer_cust_no: this.lv_cust_no
            },
            content: ['고객등록이 완료되었습니다.']
          }
        }
        this.fn_AlertPopup(t_type, t_popupObj)
      } else {
        console.log('t_data.error_msg>>>>', t_data.error_msg)
        if (t_data.error_msg === '150004') {
          t_popupObj = {
            content: '이미 등록된 실명번호입니다. 확인 후 다시 입력 하여 주십시오.'
          }
        } else {
          t_popupObj = {
            content: t_data.error_msg
          }
        }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_GoNextPage
     * 설명       : 설문등록 페이지로 이동
     ******************************************************************************/
    fn_GoNextPage(flag) {
      this.$router.push({
        name: 'MSPFS110M',
        params: {
          cob_rname_gb: this.lv_cob_rname_gb.value,
          ed_rname_no: flag == 'NEXT' ? this.lv_btn_next.value : [this.lv_ed_rname_no.value1, this.lv_ed_rname_no.value2]
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_SetSubmitData
     * 설명       : 등록시 데이터 매핑
     ******************************************************************************/
    fn_SetSubmitData(type) {
      console.log('fn_SetSubmitData....')

      // AS-IS
      this.lv_submit_data.bfcer_ent_path_cd = 'I' // 수익증권가입경로코드
      this.lv_submit_data.cust_info_sync_yn = this.lv_chk_cdi_sync_yn // 고객정보동기화여부

      // this.lv_submit_data.sup_id            = nexacro.getApplication().gv_adm_eno    // 확인중
      // this.lv_submit_data.aprv_sno          = nexacro.getApplication().gv_adm_seq_no // 확인중

      // 조회 필드
      // this.lv_submit_data.cust_cnf_syst_cust_cnf_yn = 'Y'
      this.lv_submit_data.bfcer_rlnm_no_knd_cd = this.lv_cob_rname_gb.value // 실명번호종류
      this.lv_submit_data.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명확인번호

      // 성명
      this.lv_submit_data.fnd_cust_nm = this.lv_cust_nm.value // 고객명
      this.lv_submit_data.fnd_cust_eng_nm = this.lv_cust_eng_nm.value // 고객영문명

      // 입력/선택 필드
      this.lv_submit_data.bfcer_naty_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_naty_cntry_cd.value, 'KR') // 국적
      this.lv_submit_data.fnd_dmcl_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_fnd_dmcl_cntry_cd.value, 'KR') // 거주국가

      this.lv_submit_data.bfcer_incmr_sc_cd = this.lv_cob_inc_earner_gb.value // 소득자구분
      this.lv_submit_data.dwlr_yn = this.lv_dwlr_yn.value // 거주자여부
      this.lv_submit_data.bfcer_lvht_sc_cd = this.lv_lvht_sc_cd.value // 생계형구분

      this.lv_submit_data.cust_birth_mmdd = this.lv_cal_birth_dt.value.replace(/-/g, '') // 생일(생년월일)
      this.lv_submit_data.bfcer_slrc_lnrc_sc_cd = this.lv_birth_solun_gb.value // 생일(생년월일) 양력음력 구분

      this.lv_submit_data.bfcer_mail_trad_cd = this.lv_mail_trad_cd.value // 우편물통보처
      this.lv_submit_data.cust_mngr_eno = this.lv_ed_mgr_empno.value // 관리자사번
      this.lv_submit_data.bfcer_fnd_blnc_ntc_sc_cd = this.lv_fnd_blnc_ntc_sc_cd.value // 펀드잔고통보처

      // 신용정보동의여부
      this.lv_submit_data.crd_int_yn1 = 'Y' // 계약체결
      this.lv_submit_data.crd_int_yn2 = this.lv_marketing_agree.value // 마케팅동의여부
      this.lv_submit_data.mkt_new_gb = this.lv_marketing_agree.value == 'Y' ? 'NEW' : '' // 마케팅신규여부

      if (this.lv_marketing_agree.value == 'Y') {
        this.lv_submit_data.crd_strymd = this.lv_marketing_date.value1 // 마케팅동의 시작일자
        this.lv_submit_data.crd_endymd = this.lv_marketing_date.value2.replace(/-/g, '') // 마케팅동의 종료일자
        this.lv_submit_data.crd_term = this.lv_marketing_date.value3 // 마케팅동의 유효년수

        this.lv_submit_data.mkt_cnvs_mth_tel_yn = this.lv_agree_phone ? 'Y' : 'N' // 마케팅연락방식 > 전화
        this.lv_submit_data.mkt_cnvs_mth_sms_yn = this.lv_agree_sms ? 'Y' : 'N' // 마케팅연락방식 > 문자
        this.lv_submit_data.mkt_cnvs_mth_email_yn = this.lv_agree_email ? 'Y' : 'N' // 마케팅연락방식 > 이메일
        this.lv_submit_data.mkt_cnvs_mth_pst_yn = this.lv_agree_post ? 'Y' : 'N' // 마케팅연락방식 > 우편
      } else {
        // 마케팅 동의여부가 아니오('N') 일 경우에도 마케팅동의 관련 값 없으면 에러발생하여 임의값 매핑
        this.lv_submit_data.crd_strymd = FSCommUtil.gfn_strToday(1) // 마케팅동의 시작일자
        this.lv_submit_data.crd_endymd = '99991231' // 마케팅동의 종료일자
        this.lv_submit_data.crd_term = '0' // 마케팅동의 유효년수

        this.lv_submit_data.mkt_cnvs_mth_tel_yn = 'N' // 마케팅연락방식 > 전화
        this.lv_submit_data.mkt_cnvs_mth_sms_yn = 'N' // 마케팅연락방식 > 문자
        this.lv_submit_data.mkt_cnvs_mth_email_yn = 'N' // 마케팅연락방식 > 이메일
        this.lv_submit_data.mkt_cnvs_mth_pst_yn = 'N' // 마케팅연락방식 > 우편
      }

      // 주연락처주소코드
      this.lv_submit_data.bfcer_mn_ctadr_addr_cd = this.lv_main_dm.value

      /*********************** 자택 ************************************/

      if (!FSCommUtil.gfn_isNull(this.lv_home_addr.zipNum)) {
        // 우편번호 값 나눠주는 함수 호출(공통)
        let t_home_pstcdObj = FSCommUtil.gfn_pstcd_substr(this.lv_home_addr.zipNum)

        // 주소
        this.lv_submit_data.hom_addr_pstcd01 = t_home_pstcdObj.pstcd01 // 우편번호 앞 3자리
        this.lv_submit_data.hom_addr_pstcd02 = t_home_pstcdObj.pstcd02 // 우편번호 나머지

        this.lv_submit_data.cust_hom_pstcd_addr = this.lv_home_addr.value1 // 우편번호주소
        this.lv_submit_data.hom_bldg_no_encr = this.lv_home_addr.value2 // 건물번호
        this.lv_submit_data.cust_hom_dtlad_encr = this.lv_home_addr.value3 // 상세주소암호화
        this.lv_submit_data.hom_adtn_addr_encr = this.lv_home_addr.value4 // 부가주소
        this.lv_submit_data.hom_addr_stdzt_yn = this.lv_home_addr.standard ? 'Y' : 'N' // 표준화여부
      }

      // 전화번호
      this.lv_submit_data.hom_dstno_encr = this.lv_home_no.value1
      this.lv_submit_data.hom_tlnum_no_encr = this.lv_home_no.value2
      this.lv_submit_data.hom_bkno_sno_encr = this.lv_home_no.value3

      /*********************** 자택 ************************************/

      /*********************** 직장 ************************************/

      // 우편번호 값 나눠주는 함수 호출(공통)
      if (!FSCommUtil.gfn_isNull(this.lv_job_addr.zipNum)) {
        let t_job_pstcdObj = FSCommUtil.gfn_pstcd_substr(this.lv_job_addr.zipNum)

        // 주소
        this.lv_submit_data.jobp_addr_pstcd01 = t_job_pstcdObj.pstcd01 // 우편번호 앞 3자리
        this.lv_submit_data.jobp_addr_pstcd02 = t_job_pstcdObj.pstcd02 // 우편번호 나머지

        this.lv_submit_data.cust_jobp_pstcd_addr = this.lv_job_addr.value1 // 우편번호주소
        this.lv_submit_data.jobp_bldg_no_encr = this.lv_job_addr.value2 // 건물번호
        this.lv_submit_data.cust_jobp_dtlad_encr = this.lv_job_addr.value3 // 상세주소암호화
        this.lv_submit_data.jobp_adtn_addr_encr = this.lv_job_addr.value4 // 부가주소
        this.lv_submit_data.jobp_addr_stdzt_yn = this.lv_job_addr.standard ? 'Y' : 'N' // 표준화여부
      }

      // 전화번호
      this.lv_submit_data.jobp_dstno_encr = this.lv_job_no.value1
      this.lv_submit_data.jobp_tlnum_no_encr = this.lv_job_no.value2
      this.lv_submit_data.jobp_bkno_sno_encr = this.lv_job_no.value3

      // 직장명
      this.lv_submit_data.cust_jobp_nm = this.lv_job_name.value

      // 부서명
      this.lv_submit_data.jobp_ins_dept_nm = this.lv_dept_name.value

      /*********************** 직장 ************************************/

      // FAX
      this.lv_submit_data.fax_dstno_encr = this.lv_fax_no.value1
      this.lv_submit_data.fax_tlnum_no_encr = this.lv_fax_no.value2
      this.lv_submit_data.fax_bkno_sno_encr = this.lv_fax_no.value3

      // 휴대폰
      this.lv_submit_data.bman_celph_dstno_encr = FSCommUtil.gfn_isNull_replace(this.lv_phone_no.value1, '010')
      this.lv_submit_data.bman_celph_tlnum_no_encr = this.lv_phone_no.value2
      this.lv_submit_data.bman_celph_bkno_sno_encr = this.lv_phone_no.value3

      // email
      this.lv_submit_data.cust_emai_addr_encr = this.lv_email_addr.value1
      this.lv_submit_data.cust_emai_dm_nm = this.lv_email_addr.value2

      // CDI 데이터 매핑
      this.lv_submit_data.CU100000_O_06VO = this.lv_cdi_bp_data // AS-IS: ds_cdi_bp
      this.lv_submit_data.CU100000_O_07VO = this.lv_cdi_pass_data // AS-IS: ds_cdi_pass
      this.lv_submit_data.CU100000_O_08VO = this.lv_cdi_addr_data // AS-IS: ds_cdi_addr
      this.lv_submit_data.CU100000_O_10VO = this.lv_cdi_mka_data // AS-IS: ds_cdi_mka
    },

    /******************************************************************************
     * Function명 : fn_SubmitVaild
     * 설명       : 등록시 필수값 체크
     ******************************************************************************/
    fn_SubmitVaild() {
      console.log('fn_SubmitVaild....')

      let t_return = true

      // gfn_dataSet 으로 생성된 객체만 추가
      let t_vaildList1 = [
        this.lv_cust_nm, // 고객명
        this.lv_naty_cntry_cd, // 국적
        this.lv_fnd_dmcl_cntry_cd, // 거주국가
        this.lv_ed_mgr_empno, // 관리자사번
        this.lv_cob_inc_earner_gb, // 소득자구분
        this.lv_dwlr_yn, // 거주자여부
        this.lv_marketing_agree, // 마케팅동의여부  **
        this.lv_mail_trad_cd, // 우편물통보처
        this.lv_fnd_blnc_ntc_sc_cd // 펀드잔고통보처 **
      ]

      if (FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return)) {
        t_return = false
      }

      //마케팅동의여부
      // if( FSCommUtil.gfn_isNull(this.lv_marketing_agree.value) ){
      //   FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
      //   this.$refs['marketing_agree'].$el.focus()
      //   FSCommUtil.gfn_focus(this.$refs['marketing_agree'])
      //   this.lv_marketing_agree.error = true
      //   t_return = false
      // }

      // //관리자
      // else if( FSCommUtil.gfn_isNull(this.lv_ed_mgr_empno.value) ){
      //   FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
      //   this.lv_ed_mgr_empno.error = true
      //   t_return = false
      // }

      // //펀드잔고통보처
      // else if( FSCommUtil.gfn_isNull(this.lv_fnd_blnc_ntc_sc_cd.value) ){
      //   FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
      //   this.$refs['fnd_blnc_ntc_sc_cd'].$el.focus()
      //   this.lv_fnd_blnc_ntc_sc_cd.error = true
      //   t_return = false
      // }

      // 우편물통보처 자택/직장
      this.lv_home_addr.error = false
      this.lv_job_addr.error = false

      if (this.lv_mail_trad_cd.value == '11') {
        // 자택
        if (FSCommUtil.gfn_isNull(this.lv_home_addr.zipNum)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
          }
          this.lv_home_addr.error = true
          t_return = false
        } else {
          if (FSCommUtil.gfn_length(this.lv_home_addr.zimNum) > 5) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요.(우편번호 체계오류)')
            }
            this.lv_home_addr.error = true
            t_return = false
          }
          if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_home_addr.value2))) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '자택 도로명주소로 입력하세요.(빌딩주소필수)')
            }
            this.lv_home_addr.error = true
            t_return = false
          }
        }
      } else if (this.lv_mail_trad_cd.value == '12') {
        // 직장
        if (FSCommUtil.gfn_isNull(this.lv_job_addr.zipNum)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')
          }
          this.lv_job_addr.error = true
          t_return = false
        } else {
          if (FSCommUtil.gfn_length(this.lv_job_addr.zimNum) > 5) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요.(우편번호 체계오류)')
            }
            this.lv_job_addr.error = true
            t_return = false
          }
          if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_job_addr.value2))) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '직장 도로명주소로 입력하세요.(빌딩주소필수)')
            }
            this.lv_job_addr.error = true
            t_return = false
          }
        }
      }

      // 우편물통보처 스마트 / 우편물통보처 이메일 또는 펀드잔고통보처 이메일
      this.lv_phone_no.error = false
      this.lv_email_addr.error = false

      if (this.lv_mail_trad_cd.value == '15' || this.lv_mail_trad_cd.value == '14' || this.lv_fnd_blnc_ntc_sc_cd.value == '14') {
        // 우편물통보처 스마트
        if (this.lv_mail_trad_cd.value == '15') {
          if (FSCommUtil.gfn_isNull(this.lv_phone_no.value1) || FSCommUtil.gfn_isNull(this.lv_phone_no.value2) || FSCommUtil.gfn_isNull(this.lv_phone_no.value3)) {
            if (t_return) {
              FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

              if (FSCommUtil.gfn_isNull(this.lv_phone_no.value2)) {
                this.$refs['phone_no2'].focus()
              } else if (FSCommUtil.gfn_isNull(this.lv_phone_no.value3)) {
                this.$refs['phone_no3'].focus()
              }
            }
            this.lv_phone_no.error = true
            t_return = false
          }
        }

        if (FSCommUtil.gfn_isNull(this.lv_email_addr.value1) || FSCommUtil.gfn_isNull(this.lv_email_addr.value2)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

            if (FSCommUtil.gfn_isNull(this.lv_email_addr.value1)) {
              this.$refs['email_addr1'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_email_addr.value2)) {
              this.$refs['email_addr2'].focus()
            }
          }
          this.lv_email_addr.error = true
          t_return = false
        }
      }

      // 주연락처 자택('1'), 직장('2')
      this.lv_home_no.error = false
      this.lv_job_no.error = false

      if (this.lv_main_dm.value == '1') {
        if (FSCommUtil.gfn_isNull(this.lv_home_no.value1) || FSCommUtil.gfn_isNull(this.lv_home_no.value2) || FSCommUtil.gfn_isNull(this.lv_home_no.value3)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

            if (FSCommUtil.gfn_isNull(this.lv_home_no.value1)) {
              this.$refs['home_no1'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_home_no.value2)) {
              this.$refs['home_no2'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_home_no.value3)) {
              this.$refs['home_no3'].focus()
            }
          }
          this.lv_home_no.error = true
          t_return = false
        }
      } else if (this.lv_main_dm.value == '2') {
        if (FSCommUtil.gfn_isNull(this.lv_job_no.value1) || FSCommUtil.gfn_isNull(this.lv_job_no.value2) || FSCommUtil.gfn_isNull(this.lv_job_no.value3)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

            if (FSCommUtil.gfn_isNull(this.lv_job_no.value1)) {
              this.$refs['job_no1'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_job_no.value2)) {
              this.$refs['job_no2'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_job_no.value3)) {
              this.$refs['job_no3'].focus()
            }
          }
          this.lv_job_no.error = true
          t_return = false
        }
      }

      return t_return
    },

    /******************************************************************************
     * Function명 : fn_rname_chk02
     * 설명       : 등록시 값에 따른 체크
     ******************************************************************************/
    fn_rname_chk02() {
      // 검증 초기화
      this.lv_ed_mgr_empno.error = false
      this.lv_naty_cntry_cd.error = false
      this.lv_fnd_dmcl_cntry_cd.error = false
      this.lv_dwlr_yn.error = false
      this.lv_cob_inc_earner_gb.error = false
      this.lv_cob_rname_gb.error = false
      this.lv_appoint_cust_no.error = false
      this.lv_home_addr.error = false
      this.lv_job_addr.error = false

      // 관리자사번 검증
      if (FSCommUtil.gfn_length(this.lv_ed_mgr_empno.value) > 0) {
        if (FSCommUtil.gfn_length(this.lv_ed_mgr_empno.value) != 5) {
          FSCommUtil.gfn_validate(this, 'FC는 관리자가 아닙니다.')
          this.lv_ed_mgr_empno.error = true
          return false
        }
      }

      // 실명번호 종류에 따른 검증
      if (this.lv_cob_rname_gb.value == '01') {
        // 주민등록번호
        if (this.lv_naty_cntry_cd.value != 'KR') {
          FSCommUtil.gfn_validate(this, '국적을 확인하십시오')
          this.lv_naty_cntry_cd.error = true
          return false
        }

        if (this.lv_fnd_dmcl_cntry_cd.value != 'KR') {
          FSCommUtil.gfn_validate(this, '거주국을 확인하십시오')
          this.lv_fnd_dmcl_cntry_cd.error = true
          return false
        }

        if (this.lv_dwlr_yn.value == 'N') {
          FSCommUtil.gfn_validate(this, '거주여부를 확인하십시오')
          this.lv_dwlr_yn.error = true
          return false
        }

        if (this.lv_cob_inc_earner_gb.value != '111') {
          FSCommUtil.gfn_validate(this, '소득자구분을 개인(주민등록번호)로 하십시오')
          this.lv_cob_inc_earner_gb.error = true
          return false
        }
      } else if (this.lv_cob_rname_gb.value == '04') {
        // 외국인등록번호
        if (this.lv_naty_cntry_cd.value == 'KR') {
          FSCommUtil.gfn_validate(this, '국적을 확인하십시오')
          this.lv_naty_cntry_cd.error = true
          return false
        }

        // 거주여부: 예
        if (this.lv_dwlr_yn.value == 'Y') {
          if (this.lv_fnd_dmcl_cntry_cd.value != 'KR') {
            FSCommUtil.gfn_validate(this, '거주국을 확인하십시오')
            this.lv_fnd_dmcl_cntry_cd.error = true
            return false
          }
        } else {
          // 거주여부: 아니요(비거주자)
          if (this.lv_fnd_dmcl_cntry_cd.value == 'KR') {
            FSCommUtil.gfn_validate(this, '거주국을 확인하십시오')
            this.lv_fnd_dmcl_cntry_cd.error = true
            return false
          }
        }

        if (this.lv_cob_inc_earner_gb.value != '131') {
          FSCommUtil.gfn_validate(this, '소득자구분을 개인(외국인등록번호)로 하십시오')
          this.lv_cob_inc_earner_gb.error = true
          return false
        }
      } else if (this.lv_cob_rname_gb.value == '05') {
        if (this.lv_fnd_dmcl_cntry_cd.value != 'KR') {
          FSCommUtil.gfn_validate(this, '거주국이 올바르지 않습니다.')
          return false
        }

        if (this.lv_cob_inc_earner_gb.value != '141') {
          FSCommUtil.gfn_validate(this, '소득자 구분이 올바르지 않습니다.')
          return false
        }

        if (this.lv_dwlr_yn.value != 'Y') {
          FSCommUtil.gfn_validate(this, '거주자여부가 올바르지 않습니다.')
          return false
        }
      } else {
        FSCommUtil.gfn_validate(this, '실명번호 종류가 올바르지 않습니다.')
        this.lv_cob_rname_gb.error = true
        return false
      }

      // 주소표준화 여부 검증
      if (!FSCommUtil.gfn_isNull(this.lv_home_addr.zipNum) && !this.lv_home_addr.standard) {
        FSCommUtil.gfn_validate(this, '주소표준화 검증을 위해 자택 주소를 다시 확인해주세요.')
        this.lv_home_addr.error = true
        return false
      }

      if (!FSCommUtil.gfn_isNull(this.lv_job_addr.zipNum) && !this.lv_job_addr.standard) {
        FSCommUtil.gfn_validate(this, '주소표준화 검증을 위해 직장 주소를 다시 확인해주세요.')
        this.lv_job_addr.error = true
        return false
      }

      // 지정고객번호 검증
      if (!FSCommUtil.gfn_isNull(this.lv_appoint_cust_no.value)) {
        if (!FSCommUtil.gfn_isNum(this.lv_appoint_cust_no.value) || Number(this.lv_appoint_cust_no.value) < 1000000 || Number(this.lv_appoint_cust_no.value) > 9000000) {
          FSCommUtil.gfn_validate(this, '지정고객번호가 유효하지 않습니다.')
          this.lv_appoint_cust_no.error = true
          this.$refs['appoint_cust_no'].focus()
          return false
        }
      }

      return true
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      let formList = []

      // 커버발행 파라미터 저장
      this.$bizUtil.fsUtils.saveCvrPblParam(this, { bfcer_cust_no: this.lv_cust_no })

      let FS000001 = { formId: 'FS000001' }
      if (this.isMobile) {
        FS000001.mappingData = {
          rname_no: this.lv_ed_rname_no.value1 + '-' + FSCommUtil.gfn_substr(this.lv_masked_val, 0, 1)
        }
      } else {
        FS000001.mappingData = {
          rname_no: this.lv_ed_rname_no.value1 + '-' + FSCommUtil.gfn_substr(this.lv_ed_rname_no.value2, 0, 1)
        }
      }

      let searchData = {
        bfcer_cust_no: this.lv_cust_no, // 수익증권고객번호, Length : 7
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        fnd_cust_nm: this.lv_submit_data.fnd_cust_nm, // 펀드고객명, Length : 100
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2, // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
        tr_code: 'CU1000'
      }

      FS000001.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'selectCU100000_InfoReport'
      }
      formList.push(FS000001)

      let FS000005 = { formId: 'FS000005' }

      // 마케팅 동의여부 "예" 일 경우
      if (this.lv_marketing_agree.value == 'Y') {
        FS000005.mappingData = {
          adChk: this.lv_marketing_agree.value,
          chkSms: this.lv_agree_sms ? 'Y' : 'N',
          chkEmail: this.lv_agree_email ? 'Y' : 'N',
          chkArs: this.lv_agree_phone ? 'Y' : 'N',
          chkDm: this.lv_agree_post ? 'Y' : 'N',
          custNm: this.lv_cust_nm.value
        }

        // 연락방식 전부 승인일 경우
        if (this.lv_agree_phone && this.lv_agree_sms && this.lv_agree_email && this.lv_agree_post) {
          FS000005.mappingData.chkAll = 'Y'
        }
      } else {
        FS000005.mappingData = {
          adChk: this.lv_marketing_agree.value,
          custNm: this.lv_cust_nm.value
        }
      }
      formList.push(FS000005)

      FSInfoUtil.commReportInfo(this, formList, this.fn_GoNextPage)
    },

    // 전자서식 테스트용
    fn_ReportPrintTest() {
      console.log('fn_ReportPrint....')

      let formList = []

      let FS000001 = { formId: 'FS000001' }
      FS000001.mappingData = {
        rname_no: '650820-2'
      }
      let searchData = {
        bfcer_cust_no: '1001189', // 수익증권고객번호, Length : 7
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        fnd_cust_nm: '대코팡', // 펀드고객명, Length : 100
        rlnm_cnf_no_encr: '6508202103632', // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
        tr_code: 'CU1000'
      }
      FS000001.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'selectCU100000_InfoReport'
      }
      formList.push(FS000001)

      let FS000005 = { formId: 'FS000005' }
      // 마케팅 동의여부 "예" 일 경우
      if (this.lv_marketing_agree.value == 'Y') {
        FS000005.mappingData = {
          adChk: this.lv_marketing_agree.value,
          chkSms: this.lv_agree_sms ? 'Y' : 'N',
          chkEmail: this.lv_agree_email ? 'Y' : 'N',
          chkArs: this.lv_agree_phone ? 'Y' : 'N',
          chkDm: this.lv_agree_post ? 'Y' : 'N',
          custNm: this.lv_cust_nm.value
        }

        // 연락방식 전부 승인일 경우
        if (this.lv_agree_phone && this.lv_agree_sms && this.lv_agree_email && this.lv_agree_post) {
          FS000005.mappingData.chkAll = 'Y'
        }
      } else {
        FS000005.mappingData = {
          adChk: this.lv_marketing_agree.value,
          custNm: this.lv_cust_nm.value
        }
      }
      formList.push(FS000005)

      FSInfoUtil.commReportInfo(this, formList)
    },

    /***************************************************************************************
     * Function명 : fn_Keyup
     * 설명       : keyup
     * flag      : trim (공백제거) / del (제거)
     *****************************************************************************************/
    fn_Keyup(event, pData, flag, mGbn = '') {
      const t_objName = pData.objName
      const t_keyName = pData.keyName

      if (FSCommUtil.gfn_isNull(flag)) {
        // flag 없을 경우 처리
      } else {
        const spaceCheck = (s) => /^\s+|\s+$/g.test(s)
        if (flag == 'trim') {
          if (spaceCheck(this[t_objName][t_keyName])) {
            FSCommUtil.gfn_validate(this, '공백입력은 허용되지 않습니다. 작성에 주의하시기 바랍니다.')
            this[t_objName][t_keyName] = FSCommUtil.gfn_trim(this[t_objName][t_keyName])
          }
        } else if (flag == 'del') {
          this[t_objName][t_keyName] = ''
        } else if (flag === 'ssnf') {
          // ASR240701140 / 고객명 띄어쓰기 허용
          this[t_objName]['error'] = ''
          this[t_objName][t_keyName] = FSCommUtil.gfn_trim(this[t_objName][t_keyName])

          let custGbn = this.lv_cob_rname_gb.value === '01' ? 'ZPER01' : 'ZPER02'
          if (!FSCommUtil.isCustNameValidation(this[t_objName][t_keyName], custGbn)) {
            if (mGbn !== 'I') {
              FSCommUtil.gfn_validate(this, '고객명이 유효하지 않습니다. 확인 후 다시 입력해주세요.')
            }
            this[t_objName]['error'] = 'error'
            this.$refs[this[t_objName].ref].focus()
            return false
          }
          return true
        }
      }
    },

    /***************************************************************************************
     * Function명 : fn_NextFocus
     * 설명       : next focus
     *****************************************************************************************/
    fn_NextFocus(event) {
      console.log('this.isMobile>>', this.isMobile)
      // 실명번호
      if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6) {
        if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
          this.$refs['ed_rname_no2'].focus()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_ChangeMailTrad
     * 설명       : 우편물통보처 change
     ******************************************************************************/
    fn_ChangeMailTrad(pData) {
      if (pData.value == '11') {
        // 자택
        this.lv_home_addr.class = true
        this.lv_job_addr.class = false
        this.lv_phone_no.class = false
        this.lv_email_addr.class = false
        this.lv_infoGuide = '※ 자택정보를 필수 입력해야 합니다.'
      } else if (pData.value == '12') {
        // 직장
        this.lv_home_addr.class = false
        this.lv_job_addr.class = true
        this.lv_phone_no.class = false
        this.lv_email_addr.class = false
        this.lv_infoGuide = '※ 직장정보를 필수 입력해야 합니다.'
      } else if (pData.value == '14') {
        // email
        this.lv_home_addr.class = false
        this.lv_job_addr.class = false
        this.lv_phone_no.class = false
        this.lv_email_addr.class = true
        this.lv_infoGuide = '※ 직장 또는 자택정보를 필수 입력해야 합니다.'
      } else if (pData.value == '15') {
        // email
        this.lv_home_addr.class = false
        this.lv_job_addr.class = false
        this.lv_phone_no.class = true
        this.lv_email_addr.class = true
        this.lv_infoGuide = '※ 전화번호는 자택, 직장 중에 필수 입력해야 합니다.'
      } else {
        this.lv_home_addr.class = false
        this.lv_job_addr.class = false
        this.lv_phone_no.class = false
        this.lv_email_addr.class = false
        this.lv_infoGuide = '※ 전화번호는 자택, 직장 중에 필수 입력해야 합니다.'
      }

      // 펀드잔고통보처
      if (this.lv_fnd_blnc_ntc_sc_cd.value == '14') {
        // email
        this.lv_email_addr.class = true
      }
    },

    fn_clear(param) {
      switch (param) {
        case 'fndBlnc':
          this.lv_fnd_blnc_ntc_sc_cd.error = false
          break
        case 'marketing':
          this.lv_marketing_agree.error = false
          break
        case 'phone':
          this.lv_phone_no.error = false
          break
        case 'email':
          this.lv_email_addr.error = false
          break
        case 'appointCust':
          this.lv_appoint_cust_no.error = false
      }
    }
  }
}
</script>
<style scoped></style>
