/*
 * 업무구분: 고객
 * 화 면 명: MSPFS104P
 * 화면설명: 고객정보_P
 * 작 성 일: 2023.02.22
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal medium" ref="modal" title="고객정보">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table">
            <table class="table row-type">
              <thead>
                <tr>
                  <!-- <th scope="col"></th> -->
                  <th scope="col">고객번호</th>
                  <th scope="col">실명번호</th>
                  <th scope="col">고객명</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(listArray, i) in lv_custSearchList" :key="i" :class="lv_selectRadioList[i] ? 'checked' : ''">
                  <!-- <td><mo-radio v-model="lv_selectRadioList[i]" @click="fn_CustListSelect(i)" /></td> -->
                  <td>{{ lv_custSearchList[i].bfcer_cust_no }}</td>
                  <td>{{ lv_custSearchList[i].rlnm_cnf_no_encr }}</td>
                  <td>{{ lv_custSearchList[i].fnd_cust_nm }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button size="large" @click="fn_Close()">닫기</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS104P",
    screenId: "MSPFS104P",
    components: {},
    props: {
      popupObj: {type:Object, default:null},
    },    

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_custSearchList: [],
        lv_selectRadioList: [],
        lv_selectIdx: null,

      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS104P')
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_Open() {
        this.fn_Init()
        this.modal.open()
      },
      fn_Close() {
        this.modal.close()
      },
      

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        this.popupObj.custSearchList.forEach((item, idx) => {
          item.rlnm_cnf_no_encr = FSCommUtil.gfn_substr(item.rlnm_cnf_no_encr, 0, 6) + '-' + FSCommUtil.gfn_substr(item.rlnm_cnf_no_encr, 6, 7)
        })

        this.lv_custSearchList = this.popupObj.custSearchList   // 조회 list
        this.lv_custSearchList.forEach((item, idx) => {
          this.lv_selectRadioList[idx] = false                  // 조회 list Radio 초기화
        })

        this.lv_selectIdx = null                                // 조회 list 선택한 row
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert 팝업
       ******************************************************************************/
      fn_AlertPopup(pData) {
          this.$emit('fs-alert-popup', 0, pData)
      },

      /******************************************************************************
       * Function명 : fn_CustListSelect
       * 설명       : 고객정보 list 선택
       ******************************************************************************/
      fn_CustListSelect(pIdx) {
        this.lv_selectRadioList.forEach((item, idx) => {
          if(pIdx == idx) {
            this.lv_selectRadioList[idx] = true
          } else {
            this.lv_selectRadioList[idx] = false
          }
        })
      },

      /******************************************************************************
       * Function명 : fn_SelectCheck
       * 설명       : 고객정보 list 선택 체크
       ******************************************************************************/
      fn_SelectCheck() {
        let t_flag = false
        this.lv_selectRadioList.forEach((item, idx) => {
          if(item) {
            t_flag = true
            this.lv_selectIdx = idx
          }
        })
        return t_flag
      },

    }
  }
</script>
<style scoped>
</style>